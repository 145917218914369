<template>
  <div class="content-container">
    <el-main class="padding0">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread-bg conTop">
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        <el-breadcrumb-item v-show="titles != undefined" v-for="(item, index) in titles" :key="index">{{ item }}</el-breadcrumb-item>
        <div>
          <el-button type="text" size="mini" v-show="showBtn" @click="cancelList">返回上一页</el-button>
        </div>
      </el-breadcrumb>
    </el-main>
  </div>
</template>

<script>
export default {
  props: ['title', 'titles', 'showBtn', 'route', 'id'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 返回列表路由
    cancelList() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="stylus" scoped>
.content-container .conTop {
  overflow: hidden;
  width: 100%;
  height: 41px;
  text-align: left;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 15px;
  border-radius: 10px 10px 10px 10px;
  /* background: linear-gradient(0deg, rgba(229, 244, 251, 1) 0%, rgba(199, 235, 255, 1) 50%, rgba(229, 244, 251, 1) 100%); */
}

.content-container .conTop>img {
  margin-right: 6px;
}

.content-container .conTop span.address {
  color: #65AEF6;
  font-size: 14px;
  margin-right: 12px;
}

.content-container .conTop span.title {
  color: #000;
  font-size: 14px;
}

.bread-bg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: rgba(232, 247, 255, 1);
  /* background:url(../../assets/ifram-head.png) repeat-x; */
  background-size: 100% 100%;
}

.bread-bg i {
  margin: 0 6px;
}

.bread-bg div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}
</style>
