<template>
  <div class="headmap-detail">
    <headerTitle title="头图管理" :titles="['详情']" :showBtn="true"></headerTitle>
        <div class="content">
            <p class="title">头图详情</p>
            <div class="infobox">
                <div class="info_l">
                    <el-form  ref="form" :model="form" label-width="60px">
                        <el-form-item label="标题：">
                            <el-input disabled v-model="form.title" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item label="简介：">
                            <el-input disabled type="textarea" rows="4" resize="none" v-model="form.introduction" placeholder="请输入简介"></el-input>
                        </el-form-item>
                        <el-form-item label="封面：">
                            <!-- <img style="width: 160px;height: 90px;" src="../../assets/banner.png" alt=""> -->
                            <!-- <el-upload
                                action="https://jin1.com.cn/jinyi-util/upload/uploadImage"
                                class="avatar-uploader"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                            > -->
                            <img style="width: 160px;height: 90px;" :src="form.fileUrl" class="avatar">

                            <!-- <p class="btnUp" v-else><i class="el-icon-picture-outline avatar-uploader-icon"></i>选择文件上传封面</p> -->

                            <!-- </el-upload> -->
                        </el-form-item>
                    </el-form>
                </div>
                <div class="info_r">
                    <div class="contents" v-if="form.type==0"  v-html="form.content"></div>
                    <!-- <VueQuillEditor v-else  v-model="form.questionLibraryContent"></VueQuillEditor> -->
                    <!-- <el-upload
                      class="avatar-uploader el-upload--text"
                      action="https://jin1.com.cn/jinyi-util/upload/uploadVideoFile"
                      :show-file-list="false"
                      :on-success="handleVideoSuccess"
                      :before-upload="beforeUploadVideo"
                      :on-progress="uploadVideoProcess"
                    > -->
                    <div class="videosContent"  v-if="form.type==1">
                        <video

                          :src="form.videoUrl"
                          class="videos"
                          controls="controls"
                        >
                      </video>
                    </div>
                      <!-- <i
                        v-else-if="videoForm.storageurl == '' && videoFlag == false"
                        class="el-icon-plus avatar-uploader-icon"
                      ></i>
                      <el-progress
                        v-if="videoFlag == true"
                        type="circle"
                        :percentage="videoUploadPercent"
                        style="margin-top:30px;"
                      ></el-progress>
                    </el-upload> -->
                    <!-- <el-button class="saveBtn" type="primary" round >保存</el-button> -->
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
// import VueQuillEditor from "../../components/VueQuillEditor.vue";
import { sysBannerDetail } from 'api/index.js';
export default {
    name: 'HeadmapDetail',
    components: {
        headerTitle,
        // VueQuillEditor
    },
    data() {
        return {
            form: {

            },
            isDetial: false,
            imageUrl: "",
            videoForm: {
              storageurl: ""// 视频地址
              },
              videoFlag: false
        };
    },
    mounted() {
      this.getDetial();
    },
    methods: {
      getDetial() {
        sysBannerDetail({ id: this.$route.query.id }).then((res) => {
          if (res.code === '2000') {
            this.form = res.data;
          } else {

          }
        });
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        console.log(res);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isPNG) {
          this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG || (isPNG && isLt2M);
      },
      beforeUploadVideo(file) {
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb'
        ].indexOf(file.type) === -1
      ) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
    },
    // 上传进度显示
    uploadVideoProcess(event, file, fileList) {
      console.log(event.percent, file, fileList);
      this.videoFlag = true;
      this.videoUploadPercent = Math.floor(event.percent);
    },

    // 获取上传图片地址
    handleVideoSuccess(res, file) {
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if (res.status === 200) {
        console.log(res.data);
        this.videoForm.storageurl = res.data;
      } else {
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    }
};
</script>

<style lang="stylus" scoped>
.content{
    width :100%;
    height: calc(85vh - 30px);
    background-color: #fff;
    border-radius: 10px;
    .title{
        width: 100%;
        padding: 15px 20px;
        box-sizing: border-box;
        margin-bottom: 30px;
        border-bottom: 1px solid #D9DBDE;
        color: #333333;
        font-size: 16px;
    }
}
.infobox{
    width: 100%;
    padding: 0 21px;
    box-sizing: border-box
}
.infobox::after{
    content: '';
    display: block;
    clear: both;
}
.infobox .info_l{
    width:45%;
    float left
}
.infobox .info_r{
    width: 53%
    float: right;
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 160px;
    height: 90px;
  }
  >>>.avatar-uploader .el-upload{
    display: block !important;
    border: none !important;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    display: block;
    text-align: center;
  }
  .avatar {
    width: 160px;
    height: 90px;
    display: block;
  }
  .btnUp{
    text-align: center;
    width :100%;
    margin-top: 17px;
  }
  .saveBtn{
    float:right;
    width :250px;
    height: 50px;
    line-height: 50px;
    margin-top: 23px;
    padding: 0;
    border-radius: 25px
  }
  .contents{
    width: 100%;
    height: 452px;
    border: 1px solid #D9DBDE;
    background: #EEEEEE;
    border-radius: 6px 6px 6px 6px;
    padding:21px 22px;
    box-sizing: border-box;
  }
  .videosContent{
    height: 411px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #D9DBDE;
    box-sizing: border-box;
    padding: 21px;
  }
  .videosContent .videos{
    width:100%;
    height: 100%
  }
</style>

