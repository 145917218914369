<template>
  <div class="headmap-detail">
    <headerTitle title="头图管理" :titles="['编辑']" :showBtn="true"></headerTitle>
    <div class="content">
      <p class="title">编辑</p>
      <div class="infobox">
        <div class="info_l">
          <el-form ref="form" :model="form" label-width="60px">
            <el-form-item label="标题：">
              <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="简介：">
              <el-input type="textarea" rows="4" resize="none" v-model="form.introduction" placeholder="请输入简介"></el-input>
            </el-form-item>
            <el-form-item label="封面：">
              <el-upload action="" :http-request="uploadSectionFile" class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess">
                <img v-if="form.fileUrl" :src="form.fileUrl" class="avatar" />

                <p class="btnUp" v-else>
                  <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                  选择文件上传封面
                </p>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="info_r">
          <div v-if="form.type == 1">
            <el-upload class="avatar-uploader el-upload--text videos" action="" :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess" :http-request="uploadSectionVideo" ref="myUpload">
              <video v-if="videoForm.storageurl != '' && videoFlag == false" :src="videoForm.storageurl" class="avatar videos" controls="controls">您的浏览器不支持视频播放</video>
              <div class="cont" v-else-if="videoForm.storageurl == '' && videoFlag == false">
                <p class="uploads">
                  <span><i class="el-icon-upload avatar-uploader-icon"></i></span>
                  <span>选择文件，上传视频</span>
                </p>
              </div>
              <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top: 30px"></el-progress>
            </el-upload>
            <div class="reload" @click="reloadVideo">
              <i class="el-icon-upload avatar-uploader-icon"></i>
              重新上传
            </div>
          </div>
          <div v-if="form.type == 0">
            <VueQuillEditor v-model="form.content"></VueQuillEditor>
          </div>
          <el-button class="saveBtn" type="primary" round @click="save">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import headerTitle from '@/components/title.vue';
import VueQuillEditor from '../../components/VueQuillEditor.vue';
import { uploadFile, uploadVideo, sysBannerUpdate, sysBannerDetail } from 'api/index.js';

export default {
  name: 'HeadmapDetail',
  components: {
    headerTitle,
    VueQuillEditor
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
        fileUrl: '',
        introduction: ''
      },
      imageUrl: '',
      videoForm: {
        storageurl: '' // 视频地址
      },
      videoFlag: false
    };
  },
  mounted() {
    this.getDetial();
  },
  methods: {
    getDetial() {
      sysBannerDetail({ id: this.$route.query.id }).then(res => {
        if (res.code === '2000') {
          this.form = res.data;
          this.videoForm.storageurl = res.data.videoUrl;
        } else {
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.fileUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {},
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      console.log(isJPG, isPNG);
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      // return isJPG || (isPNG && isLt2M);
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.form.fileUrl = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    beforeUploadVideo(file) {
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
    },
    uploadSectionVideo(file) {
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.file.type) === -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadVideo(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.videoForm.storageurl = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 上传进度显示
    uploadVideoProcess(event, file, fileList) {
      console.log(event.percent, file, fileList);
      this.videoFlag = true;
      this.videoUploadPercent = Math.floor(event.percent);
    },

    // 获取上传图片地址
    handleVideoSuccess(res, file) {
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if (res.status === 200) {
        console.log(res.data);
        this.videoForm.storageurl = res.data;
      } else {
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    save() {
      if (this.form.type == 1) {
        this.form.videoUrl = this.videoForm.storageurl;
      }
      this.form.createId = JSON.parse(sessionStorage.getItem('patientMsg')).id;
      this.form.createName = JSON.parse(sessionStorage.getItem('patientMsg')).name;
      this.form.status = 1;
      const datas = JSON.stringify(this.form);
      const blob = new Blob([datas], {
        type: 'text/plain'
      });
      const formData = new FormData();
      formData.append('file', blob);
      formData.append('createId', this.form.createId);
      formData.append('createName', this.form.createName);
      formData.append('title', this.form.title);
      formData.append('type', this.form.type);
      formData.append('status', this.form.status);
      formData.append('fileUrl', this.form.fileUrl);
      formData.append('id', this.form.id);
      if (this.form.type == 0) {
        formData.append('content', this.form.content);
      } else {
        formData.append('videoUrl', this.videoForm.storageurl);
      }
      sysBannerUpdate(formData)
        .then(res => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    reloadVideo() {
      this.videoForm.storageurl = '';
    }
  }
};
</script>

  <style lang="stylus" scoped>
  .content {
    width: 100%;
    padding: 0 0 70px;
    background-color: #fff;
    border-radius: 10px;

    .title {
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-bottom: 30px;
      border-bottom: 1px solid #D9DBDE;
      color: #333333;
      font-size: 16px;
    }
  }

  .infobox {
    width: 100%;
    padding: 0 21px;
    box-sizing: border-box;
  }

  .infobox::after {
    content: '';
    display: block;
    clear: both;
  }

  .infobox .info_l {
    width: 45%;
    float: left;
  }

  .infobox .info_r {
    width: 53%;
    float: right;
  }

  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 160px;
    height: 90px;
  }

  >>>.avatar-uploader .el-upload {
    display: block !important;
    border: none !important;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    display: block;
    text-align: center;
  }

  .avatar {
    width: 160px;
    height: 90px;
    display: block;
  }

  .btnUp {
    text-align: center;
    width: 100%;
    margin-top: 17px;
  }

  .saveBtn {
    float: right;
    width: 250px;
    height: 50px;
    line-height: 50px;
    margin-top: 23px;
    padding: 0;
    border-radius: 25px;
  }

  .videos {
    width: 100%;
    height: 400px;
  }

  .cont {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .cont>.uploads {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 248px;
    height: 50px;
    line-height: 50px;
    background: #F5F7FB;
    border-radius: 6px 6px 6px 6px;
    overflow: hidden;
  }

  .cont>.uploads>span:nth-of-type(1) {
    width: 50px;
    height: 50px;
    text-align: center;
    background: #2797FC;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
  }

  .cont>.uploads>span:nth-of-type(1)>i {
    color: #fff;
  }

  .cont>.uploads>span:nth-of-type(2) {
    font-size: 16px;
    color: #2797FC;
  }

  .reload {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 21px;
    color: #2797FC;
    font-size: 16px;
    cursor: pointer;
  }

  .reload>i {
    color: #2797FC;
    font-size: 26px;
    margin-right: 10px;
  }
</style>

