<template>
  <div :class="!collapse ? 'classCollapse' : ''">
    <div v-for="(item, index) in pageList" :key="index">
      <el-menu-item :index="item.url" v-if="(!item.children || !item.children.length) && verifyLimits(item.limits)" @click="routerTo(item)">
        <img class="icons" v-if="!isSubpage && $route.path != item.url" :src="item.iconMask" alt="" />
        <img class="icons" v-else-if="!isSubpage && $route.path == item.url" :src="item.icon" alt="" />
        <span slot="title" :style="isSubpage ? { paddingLeft: paddingLeft + 'px' } : ''">
          {{ item.name }}
        </span>
      </el-menu-item>
      <el-submenu :index="parentIde + String(index + 1)" v-else-if="item.children && item.children.length && verifyLimits(item.limits)">
        <template slot="title">
          <img class="icons" v-if="!isSubpage" :src="item.iconMask" style="margin-left: 8px" alt="" />
          <span :style="isSubpage ? { paddingLeft: paddingLeft + 'px' } : ''" v-if="collapse ? !(collapse && !parentIde) : true">
            {{ item.name }}
          </span>
        </template>
        <el-menu-item-group>
          <asideComponents :pageList="item.children" :isSubpage="true" :parentIde="parentIde + String(index + 1) + '-'" :paddingLeft="paddingLeft" :collapse="collapse"></asideComponents>
        </el-menu-item-group>
      </el-submenu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AsideComponents',
  props: {
    isSubpage: {
      type: Boolean,
      default: () => {
        return false;
      }
    }, // 是否是子页面
    parentIde: {
      type: String,
      default: () => {
        return '';
      }
    }, // 父页面标识
    pageList: {
      type: Array,
      required: true
    }, // 菜单数据
    paddingLeft: {
      type: Number,
      default: () => {
        return 20;
      }
    }, // 子菜单左填充
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    routerTo(item) {
      this.$router
        .push({
          path: item.url
        })
        .then(() => {})
        .catch(() => {});
    },
    verifyLimits(verifyList) {
      let flag = false;
      const limits = JSON.parse(sessionStorage.getItem('limits'));
      for (let i = 0; i < verifyList.length; i++) {
        const exists = limits.some(el => el == verifyList[i]);
        if (exists) {
          flag = true;
          break;
        }
      }
      return flag;
    }
  }
};
</script>
<style lang="stylus" scoped>
.classCollapse >>> .el-submenu__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classCollapse >>> .el-submenu__title span {
  flex: 1;
}

.classCollapse >>> .el-submenu .el-menu-item {
  min-width: auto;
}

>>> .el-menu-item-group__title {
  padding: 0;
}

.classCollapse >>> .el-submenu__icon-arrow {
  position: static;
  margin-top: 0;
  margin-left: 8px;
}

.icons {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
</style>