<template>
    <div class="editor" @ready="ready($event)" ref="editor" :style="styles"></div>
  </template>

  <script>
  import Quill from "quill";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  import resizeImage from 'quill-image-resize-module'; // 调整大小组件。
  import { ImageDrop } from 'quill-image-drop-module';
  import axios from "axios"; // 拖动加载图片组件。
  Quill.register('modules/imageDrop', ImageDrop);
  Quill.register('modules/resizeImage ', resizeImage );

  // Quill.register("modules/imageResize", ImageResize);
  const Parchment = Quill.import("parchment");
  class LineHeightAttributor extends Parchment.Attributor.Style {}
  const lineHeightStyle = new LineHeightAttributor("lineHeight", "line-height", { scope: Parchment.Scope.INLINE, whitelist: ["1", "1.5", "1.75", "2", "3", "4", "5"] });
  Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
  export default {
    name: "VueQuillEditor",
    props: {
      /* 编辑器的内容 */
      value: {
        type: String,
        default: "",
      },
      /* 高度 */
      height: {
        type: Number,
        default: null,
      },
      /* 最小高度 */
      minHeight: {
        type: Number,
        default: null,
      },
      /* 只读 */
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        Quill: null,
        currentValue: "",
        options: {
          theme: "snow",
          bounds: document.body,
          debug: "warn",
          modules: {
            // 工具栏配置
            toolbar: {
              container: [
                ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                ["blockquote", "code-block"], // 引用  代码块
                [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                [{ indent: "-1" }, { indent: "+1" }], // 缩进
                [{ size: ["small", false, "large", "huge"] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                [{ align: [] }], // 对齐方式
                ["clean"], // 清除文本格式
                ["link", "image", "video"], // 链接、图片、视频
                [
                  {
                    lineheight: [
                      "initial",
                      "1",
                      "1.5",
                      "1.75",
                      "2",
                      "3",
                      "4",
                      "5",
                    ],
                  },
                ],
              ],
              handlers: {
                  lineheight: function (value) {
                    if (value) {
                      console.log(this.quill);
                      this.quill.format('lineHeight', value);
                    } else {
                      console.log(value);
                    }
                }
              },
            },
            imageDrop: true,
            imageResize: {
              displayStyles: {
                 backgroundColor: 'black',
                 border: 'none',
                 color: 'white'
             },
              modules: ["Resize", "DisplaySize", "Toolbar"],
            },
          },
          placeholder: "请输入内容",
          readOnly: this.readOnly,
        },
      };
    },
    computed: {
      styles() {
        const style = {};
        if (this.minHeight) {
          style.minHeight = `${this.minHeight}px`;
        }
        if (this.height) {
          style.height = `${this.height}px`;
        }
        return style;
      },
    },
    watch: {
      value: {
        handler(val) {
          if (val !== this.currentValue) {
            this.currentValue = val === null ? "" : val;
            if (this.Quill) {
              this.Quill.pasteHTML(this.currentValue);
            }
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.init();
      this.Quill.root.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              this.uploadToServer(file, (res) => {
                var range = this.Quill.getSelection();
                if (range) {
                  //  在当前光标位置插入图片
                  toolbar.quill.insertEmbed(
                    range.index,
                    "image",
                    this.$ajax.defaults.baseURL + res.file.path
                  );
                  //  将光标移动到图片后面
                  toolbar.quill.setSelection(range.index + 1);
                }
              });
            });
          }
        },
        false
      );
    },
    beforeDestroy() {
      this.Quill = null;
    },
    ready() {
      this.Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
    },
    methods: {
      // ready() {
      //   Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
      // },
      uploadToServer(file, callback) {
        var that = this;
        var fd = new FormData();
        fd.append("file", file);
        axios({
          method: "post",
          url: "https://jin1.com.cn/jinyi-util/upload/uploadImage",
          dataType: "json",
          contentType: false,
          processData: false,
          data: fd,
        }).then((result) => {
          console.log(result);
          // 上传成功，准备将图片插入编辑框中
          if (result.status == 200) {
            console.log(result.data);
            // 取得上传后返回所在的路径
            var imgUrl = result.data.result;
            // 取得图片应该所在的位置
            var currentPosition = that.Quill.getSelection().index;

            // 插入编辑框
            that.Quill.insertEmbed(currentPosition, "image", imgUrl);
            // quill.insertEmbed(length, 'image', res.data.url)
            // 显示成功提示
            console.log("上传成功");
          } else {
            console.log(result);
          }
        });
        // .catch((jqXHR)=>{
        //     console.log('错误，' + jqXHR.errorMessage);
        // });
      },
      // var xhr = new XMLHttpRequest();
      // var formData = new FormData();
      // formData.append('upload', file);
      // xhr.open('post', 'http://jin1.com.cn:8080/jinyi-util/upload/uploadImage');
      // xhr.withCredentials = true;
      // xhr.responseType = 'json';
      // xhr.send(formData);
      // xhr.onreadystatechange = () => {
      //     if (xhr.readyState == 4 && xhr.status == 200) {
      //         callback(xhr.response);
      //     }
      // };
      init() {
        const editor = this.$refs.editor;
        this.Quill = new Quill(editor, this.options);
        this.Quill.pasteHTML(this.currentValue);
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          const html = this.$refs.editor.children[0].innerHTML;
          const text = this.Quill.getText();
          const quill = this.Quill;
          this.currentValue = html;
          this.$emit("input", html);
          this.$emit("on-change", { html, text, quill });
        });
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          this.$emit("on-text-change", delta, oldDelta, source);
        });
        this.Quill.on("selection-change", (range, oldRange, source) => {
          this.$emit("on-selection-change", range, oldRange, source);
        });
        this.Quill.on("editor-change", (eventName, ...args) => {
          this.$emit("on-editor-change", eventName, ...args);
        });
        var toolbar = this.Quill.getModule("toolbar");
        toolbar.addHandler("image", this.imageUpload);
      },
      imageUpload(value) {
        var that = this;
        if (value) {
          // 创建隐藏的文件上传input
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.click();
          input.onchange = function () {
            const file = input.files[0];
            // 判断文件是否为图片
            if (!/image\/\w+/.test(file.type)) {
              console.log("只能选择图片");
              return false;
            } else {
              // 使用FormData创建键值对数据
              var fd = new FormData();
              fd.append("file", file);
              axios({
                method: "post",
                url: "https://jin1.com.cn/jinyi-util/upload/uploadImage",
                dataType: "json",
                contentType: false,
                processData: false,
                data: fd,
              }).then((result) => {
                console.log(result);
                // 上传成功，准备将图片插入编辑框中
                if (result.status == 200) {
                  console.log(result.data);
                  // 取得上传后返回所在的路径
                  var imgUrl = result.data.result;
                  // 取得图片应该所在的位置
                  var currentPosition = that.Quill.getSelection().index;

                  // 插入编辑框
                  that.Quill.insertEmbed(currentPosition, "image", imgUrl);
                  // quill.insertEmbed(length, 'image', res.data.url)
                  // 显示成功提示
                  console.log("上传成功");
                } else {
                  console.log(result);
                }
              });
              // .catch((jqXHR)=>{
              //     console.log('错误，' + jqXHR.errorMessage);
              // });
            }
          };
        }
      },
    },
  };
  </script>

  <style>
  .editor,
  .ql-toolbar {
    white-space: pre-wrap !important;
    line-height: normal !important;
  }
  .quill-img {
    display: none;
  }
  .ql-container.ql-snow{
        line-height: normal !important;
        height: 400px !important;
        font-size:14px;
    }
  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "请输入链接地址:";
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "保存";
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "14px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "10px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "18px";
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "32px";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "文本";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "标题1";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "标题2";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "标题3";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "标题4";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "标题5";
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "标题6";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: "标准字体";
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: "衬线字体";
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: "等宽字体";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
    content: "行高";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1"]::before {
    content: "1";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.5"]::before {
    content: "1.5";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.75"]::before {
    content: "1.75";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2"]::before {
    content: "2";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3"]::before {
    content: "3";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="4"]::before {
    content: "4";
  }
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="5"]::before {
    content: "5";
  }
  .ql-snow .ql-picker.ql-lineheight {
    width: 70px;
  }
  </style>
