
<template>
    <div class="headmap-list">
      <headerTitle title="字典管理"></headerTitle>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane :lazy="true" v-for="(item,index) in typeList" :key="index" :id="item.id" :label="item.name" :name="item.code">
          <div class="content_box">
            <div class="searchBigBox">
            <div class="searchBox" style="">
              <div class="inputBigBox">
                <div class="inputText">{{chooseTitle}}：</div>
                <el-input class="input" clearable :placeholder="'请输入'+chooseTitle" v-model="searchObj.name" @keyup.enter="searchClick"></el-input>
              </div>
            </div>
          <div class="searchButton">
              <el-button type="primary" size="mini" plain round @click="searchClick">查询</el-button>
              <el-button type="primary" size="mini" plain  round @click="newAdd">新建</el-button>
            </div>
          </div>
        <el-table :data="dataList" style="width: 100%" v-loading="loading" stripe border>
          <el-table-column label="序号" align="center" :index="indexMethod" type="index" width="55"></el-table-column>
          <el-table-column prop="name" :label='chooseTitle' align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sort" label="排序"  align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="code" label="编码"  align="center" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="creatorId" label="创建人"  align="center" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="createTime" label="创建时间"  align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                  <el-button size="small" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                  <el-button size="small" type="text" @click="handleDelete(scope.row)">删除</el-button>
              </template>
              </el-table-column>
              <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
          </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      </div>
      <!-- 新建 -->
      <el-dialog :title="titles" :visible.sync="dialogVisibleadd" :show-close="false" center width="410px">
          <div>
            <el-form :model="form" label-width="88px" ref="form" label-position="left">
              <el-form-item :label="chooseTitle+'：'">
                <el-input v-model="form.name" :placeholder="'请输入'+chooseTitle"></el-input>
                </el-form-item>
                <el-form-item label="编码：">
                <el-input v-model="form.code" placeholder="请输入编码"></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                  <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
                  </el-form-item>
              </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="info" @click="revoke" round style="width: 46%;border-radius: 25px;">取消</el-button>
            <el-button type="primary" @click="cancel" round style="width: 46%;border-radius: 25px;">确定</el-button>
          </span>
        </el-dialog>
      <!-- 删除 -->
      <el-dialog title="删除字典" :visible.sync="dialogVisible" :show-close="false" center width="410px">
          <span style="display: block; text-align: center;">确认删除此条信息吗？</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="info" @click="dialogVisible = false" round style="width: 46%;border-radius: 25px;">取消</el-button>
            <el-button type="primary" @click="cancelDelete" round style="width: 46%;border-radius: 25px;">确定</el-button>
          </span>
        </el-dialog>
    </div>
  </template>
  <script>
  import headerTitle from "../title.vue";
  import { dicList, dicListByCode, dicAdd, dicUpdate } from 'api/index.js';
  export default {
      components: {
          headerTitle
      },
      data() {
          return {
            loading: false,
            dialogVisible: false,
            searchObj: {
                name: ""
            },
            total: 0,
            page: 1,
            pageSize: 10,
            pagenum: 1,
            dataList: [
              {
                type: '1',
                title: '1',
                name: '1',
                time: '1'
                }
            ],
            dialogVisibleadd: false,
            form: {
              name: "",
              sort: "",
              code: ""
            },
            activeName: '',
            typeList: [
              {
                  id: 1,
                  code: "GJFL",
                  name: "国家分类",
                  parentId: 0,
                  sort: 0,
                  status: 1,
                  mark: "0",
                  creatorId: null,
                  modifierId: null,
                  createTime: ""
              }
            ],
            chooseTitle: "",
            title: "",
            deleteForm: {
              id: '',
              code: '',
              name: '',
            }
          };
      },
      mounted() {
        this.getTypeList();
      },
      methods: {
        getTypeList( ) {
          dicListByCode({ code: "QUESTION_TAG", status: 1 }).then(res => {
                if (res.code == '2000') {
                  this.typeList = [...new Set([...this.typeList, ...res.data])];
                   this.activeName = this.typeList[0].code;
                   this.chooseTitle = this.typeList[0].name;
                   this.parentId = this.typeList[0].id;
                   this.getDetail();
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
              }
            });
        },
        getDetail() {
          const data = {
            code: this.activeName,
             name: this.searchObj.name,
             status: 1
          };
          dicListByCode(data).then(res => {
            if (res.code == '2000') {
              this.dataList = res.data;
              this.loading = false;
            } else {
              this.$message({
                type: 'error',
                message: res.msg
                });
            }
          });
        },
        handleDelete(row) {
            this.dialogVisible = true;
            this.deleteForm = {
              id: row.id,
              code: row.code,
              name: row.name,

            };
        },
        cancel() {
          const data = {
            code: this.form.code,
            creatorId: '',
            id: this.id || '',
            mark: '',
            modifierId: '',
            name: this.form.name,
            parentId: this.parentId, // 证件父级   id  61
            sort: this.form.sort,
            status: 1 // 0 禁用  1启用
          };
          let apis;
          if (this.titles === '新建字典') {
              apis = dicAdd;
          } else {
            apis = dicUpdate;
          }
          apis(data).then(res => {
            if (res.code == '2000') {
              dicListByCode({ code: this.activeName }).then(res => {
                if (res.code == '2000') {
                  //  this.typeList = [...new Set([...this.typeList, ...res.data])];
                   this.$message({
                    type: 'success',
                    message: res.msg
                    });
                    if ( this.$refs.form != undefined) {
                        this.$refs.form.resetFields();
                      }
                      this.id = '';
                   this.getDetail();
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
              }
            });
              this.dialogVisibleadd = false;
            } else {
              this.$message({
                type: 'error',
                message: res.msg
                });
            }
          });
        },
        handleEdit(row) {
          this.dialogVisibleadd = true;
          this.titles = '编辑字典';
          this.form = {
            name: row.name,
            sort: row.sort,
            code: row.code
          };
          this.id = row.id;
        },
        revoke() {
          this.dialogVisibleadd = false;
          this.$nextTick(() => {
            this.$refs.form.resetFields();
        });
        },
        indexMethod(index) {
              return index + 1 + (this.pagenum - 1) * this.pageSize;
        },
        toChannelClick() {

        },
        searchClick() {
           this.getDetail();
        },
        cancelDelete() {
          const params = {
            code: this.deleteForm.code,
            id: this.deleteForm.id,
            name: this.deleteForm.name,
            parentId: this.parentId, // 证件父级   id  6
            status: -1, // 0 禁用  1启用
          };
          dicUpdate(params).then((res) => {
            if (res.code == 2000) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.dialogVisible = false;
              dicList({ code: "QUESTION_TAG" }).then(res => {
                if (res.code == '2000') {
                  // this.typeList = [...new Set([...this.typeList, ...res.data])];
                    if ( this.$refs.form != undefined) {
                        this.$refs.form.resetFields();
                      }
                      this.id = '';
                   this.getDetail();
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
              }
            });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        },
        newAdd() {
          this.dialogVisibleadd = true;
          this.form = {
            name: "",
            sort: "",
            code: ""
          };
          if (this.$refs && this.$refs.form) {
            this.$refs.form.resetFields();
          }
          this.titles = '新建字典';
        },
        handleClick(tab, event) {
          this.chooseTitle = tab.label;
          this.searchObj.name = '';
          this.loading = true;
          this.typeList.forEach(item => {
            if (item.code == tab.name) {
              this.parentId = item.id;
            }
          });

          this.getDetail();
        }
      }
  };
  </script>

  <style lang="stylus" scoped>
  .headmap-list{

  }
  >>> .el-table__header th, >>> .el-table__header tr {
      background: #EBF5FF !important;
      font-size: 14px !important;
      color: #202224 !important;
    }

    >>> .el-table__body th, >>> .el-table__body tr {
      background: #fff !important;
      font-size: 14px !important;
      color: #202224 !important;
    }

    >>> .el-table__body tr .el-table__cell {
      padding: 8px 0 !important;
    }

    >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: #F5FAFF !important;
    }
    .content{
      width :100%;
      height: calc(85vh - 30px);
      background-color: #fff;
      border-radius: 10px;

      box-sizing: border-box
      }

    .searchBigBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 23px;

        .searchBox {
          display: flex;
          align-items: center;

          .inputBigBox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;

            .input {
              width: 200px;
            }

            .inputText {
              font-size: 14px;
              color: #333;
              margin-right: 6px;
            }

            .inputBox {
              width: 166px;
            }

            .inputButton {
              width: 36px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #409EFF;
              font-size: 16px;
              color: #fff;
              border-radius: 0 8px 8px 0;
              cursor: pointer;
            }
          }
        }

        .searchButton {
          display: flex;

          .el-button {
            padding: 0 !important;
            width: 80px;
            height: 28px;
            line-height: 28px;
          }
        }
      }
      .el-checkbox {
        margin-right: 10px
      }
      .typeName{
        margin-bottom: 10px
      }
      .content_box{
        padding: 20px;
      }
      >>>.el-tabs__nav-scroll{
        padding: 7px 20px 0px;
      }
      >>>.el-tabs__header{
        margin:0
      }
      >>>.el-tabs__nav-wrap::after{
        height: 1px;
      }
      >>>.el-dialog--center .el-dialog__body{
        padding:20px 26px 0;
      }
      >>>.el-form-item{
        margin-bottom:20px;
      }
      >>>.el-dialog__footer{
        padding:0 26px 28px;
      }
  </style>