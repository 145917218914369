<template>
  <div class="box jyt-container">
    <div class="box_l">
      <!-- <div class="logo">超哥留学-交叉检索系统</div> -->
      <div class="flexs-alignCenter logoBox">
        <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/logo.png" alt="" />
        客服管理CRM系统
      </div>
      <div style="line-height: 0px"></div>
    </div>

    <div class="box_r">
      <span style="margin-right: 20px">用户：{{ userName }}</span>
      <!-- <span class="out" @click="out">修改密码</span>
      <span style="margin: 0 15px;">|</span> -->
      <span class="out" @click="out">退出登录</span>
    </div>
  </div>
</template>

<script>
// import { api } from "@/api/api";
// import Cookies from 'js-cookie';
export default {
  name: '',
  data() {
    return {
      userName: '',
      selectData: [],
      show: false,
      hospitalList: [],
      keyWord: ''
    };
  },
  created() {},

  mounted() {
    this.userName = JSON.parse(sessionStorage.getItem('patientMsg')).name;
  },
  methods: {
    showHos() {
      this.show = true;
    },
    handleSearchNations(keyWord) {
      const t = [];
      console.log(keyWord);
      this.selectData.forEach(item => {
        if (item.hospitalName.indexOf(keyWord) > -1) {
          t.push(item.hospitalName);
        }
      });
      this.hospitalList = t;
    },
    onConfirm(e) {
      this.show = false;
      //   if (e != undefined) {
      //     Cookies.set("hospitalName", e);
      //     for (const item of this.selectData) {
      //       if (item.hospitalName == e) {
      //         Cookies.set("hospitalCode", item.hospitalCode, { expires: 7 });
      //         Cookies.set("hospitalType", item.hospitalType, { expires: 7 });
      //         Cookies.set("hospitalId", item.hospitalId, { expires: 7 });
      //       }
      //     }
      //     // 调用父组件中的方法
      //     this.$emit("save", "1");
      //     window.location.reload();
      // }
    },
    onCancel() {
      this.show = false;
    },
    // 退出登陆
    out() {
      localStorage.clear();
      sessionStorage.clear();
      location.href = './';
      // this.$store.commit("updateFn", {
      //   key: "islogin",
      //   data: false,
      // });
      // Dialog.confirm({
      //   title: '提示',
      //   message: '是否要退出',
      // })
      //   .then(() => {
      //     api(
      //       "auditRecipe/logout",
      //       "post",
      //       { token: Cookies.get("token") }
      //     ).then((res) => {
      //       console.log(res);
      //       if (res.data.code == 2000) {
      //         this.$message.success(res.data.msg);
      //         this.$router.push({ path: "/" });
      //         Cookies.remove('token')
      //         Cookies.remove('ptcz')
      //         Cookies.remove('hospitalId')
      //         Cookies.remove('hospitalCode')
      //         Cookies.remove('hospitalType')
      //         Cookies.remove('proxyUser')
      //         Cookies.remove('oaAccount')
      //         Cookies.remove('userType')
      //         Cookies.remove('hospitalName')
      //         Cookies.remove('userName')
      //         Cookies.remove('id')
      //         sessionStorage.clear();
      //       }
      //     });
      //   })
      //   .catch(() => {

      //   });
    }
  }
};
</script>

<style lang="stylus" scoped>
.box {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 27px;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
}

.box_r {
  color: #999;
  height: 60px;
  line-height: 60px;
}

div {
  span {
    cursor: pointer;
  }
}

.logo {
  font-size: 20px;
  color: #303243;
}

.box_l {
  display: flex;
  align-items: center;
}

.el-message-box {
  width: 100% !important;
}

.logoBox {
  font-size: 22px;

  img {
    width: 74px;
    margin-right: 15px;
  }
}
</style>