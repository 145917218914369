// 小工具
// 获取随机字符串   默认长度16
export const getRandomString = (e = 16, str = '') => {
  var t = str ? str : "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz1234567890";
  var a = t.length;
  var n = "";
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a));
  };
  return n;
};

// 时间格式化
export const formatDate = (date, format) => {
  if (!date) return '';
  if (typeof date == 'number' || typeof date == 'string') {
    date = new Date(date);
  };
  const time = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    "S+": date.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in time) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? time[k] : ("00" + time[k]).substr(("" + time[k]).length)
      );
    }
  }
  return format;
};

// 获取推车本地mac地址(ps:需要在推车安装后端的本地socket服务)
let socketTimeOut = '';
export const getMac = (message) => {
  clearTimeout(socketTimeOut);
  return new Promise((resolve, reject) => {
    const websocket = new WebSocket('ws://127.0.0.1:9089/websocket/getMacCode');
    socketTimeOut = setTimeout(() => {
      clearTimeout(socketTimeOut);
      reject({
        code: 'timeout',
        msg: '连接超时'
      });
    }, 30000);

    // 连接发生错误的回调方法
    websocket.onerror = function (err) {
      clearTimeout(socketTimeOut);
      reject({
        code: 'onerror',
        msg: '连接失败',
        data: err
      });
    };

    // 连接成功建立的回调方法
    websocket.onopen = function (event) {
      websocket.send(message);
    };

    // 接收到消息的回调方法
    websocket.onmessage = function (event) {
      clearTimeout(socketTimeOut);
      resolve(event.data);
    };

    // 连接关闭的回调方法
    websocket.onclose = function (err) {
      console.log(err);
      if (err.code != 1000) {
        reject({
          code: 'onclose',
          msg: '连接关闭',
          data: err
        });
      }
      console.log('webSocket Close!!!');
    };
  });
};
