import logMethod from '@utils/logMethod.js';
import { Notification } from 'element-ui';
import router from '@/router';
export default {
  state: {
    onLineState: sessionStorage.getItem('onLineState'), // 上下线状态
    audioUrl: 'https://files.jin1.com.cn/images/jyt-pc-doctor/orderAudio.mp3', // 提示音文件地址
    audioDom: '', // 音频dom
    callBackFunction: '' // 额外的回调函数
  },
  mutations: {
    callSetOnLineState(state, res) {
      state.onLineState = res;
    },
    // 保存audioDom
    callSetAudioDom(state, res) {
      state.audioDom = res.dom;
      state._this = res._this;
    },
    // 设置额外的回调函数
    callSetPrescriptionCallBackFunction(state, res) {
      state.callBackFunction = res;
    }
  },
  actions: {
    // 设置上下线状态
    setOnLineState(context, res) {
      context.commit('callSetOnLineState', res);
    },
    // 保存audioDom
    setAudioDom(context, res) {
      context.commit('callSetAudioDom', res);
    },
    // 设置额外的回调函数
    setPrescriptionCallBackFunction(context, res) {
      context.commit('callSetPrescriptionCallBackFunction', res);
    },
    // 订阅开方申请订单大厅
    subscriptionPrescriptionOrder({ commit, state }, res) {
      commit('callSocketSubscribe', {
        url: `/room/APP_DOCTOR_ADMISSIONS_QUEUE_${sessionStorage.getItem('hospitalCode').toUpperCase()}`,
        fun: (res) => {
          const ref = JSON.parse(res.body);
          if (ref.type == 301) {
            state._this.$nextTick(() => {
              state.audioDom.play().then(() => {
                console.log('音频播放成功');
                logMethod.writeLog(`音频播放成功--->audio音量(不代表系统音量)：${state.audioDom.volume}`);
              }).catch(e => {
                logMethod.writeLog(`音频播放失败--->` + e);
              });
            });
            Notification.info({
              title: '消息',
              message: '有新的开方申请订单!!!',
              duration: 3000,
              onClick: () => {
                router.push({
                  path: '/prescribeApplyForList'
                });
              }
            });
            if (state.callBackFunction) {
              state.callBackFunction(ref);
            }
          }
        },
        idType: `APP_DOCTOR_ADMISSIONS_QUEUE_${sessionStorage.getItem('hospitalCode').toUpperCase()}`
      });
    },
    // 取消订阅开方申请订单大厅
    unSubscriptionPrescriptionOrder({ commit, state }, res) {
      if (!sessionStorage.getItem('hospitalCode')) {
        return;
      }
      commit('callUnSocketSubscribe', `APP_DOCTOR_ADMISSIONS_QUEUE_${sessionStorage.getItem('hospitalCode').toUpperCase()}`);
    }
  }
};