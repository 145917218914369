import request from '@utils/request';

// export function uploadFile(data, onUploadProgress) {
//   return request({
//     url: '/common/uploadFile',
//     method: 'post',
//     headers: {
//       "Content-Type": "multipart/form-data"
//     },
//     onUploadProgress: (progressEvent) => {
//       if (onUploadProgress) { // onUploadProgress是上传进度
//         onUploadProgress(progressEvent);
//       }
//     },
//     data
//   }, false);
// }

// 示例库管理列表
export function documentInfoList(data) {
  return request({
    url: '/documentInfo/page',
    method: 'post',
    data
  });
}
// 示例库管理列表详情
export function documentInfo(data) {
  return request({
    url: '/documentInfo/get',
    method: 'post',
    data
  });
}
// 示例库管理列表编辑
export function documentInfoupdate(data) {
  return request({
    url: '/documentInfo/update',
    method: 'post',
    data
  });
}
// 示例库管理列表删除
export function documentInfodelete(data) {
  return request({
    url: '/documentInfo/delete',
    method: 'post',
    data
  });
}
// 示例库管理
export function getDictTreeByCode(data) {
  return request({
    url: '/pc/sys/dic/getDictTreeByCode',
    method: 'post',
    data
  });
}
// 字典
export function dicList() {
  return request({
    url: `/pc/sys/dic/list`,
    method: 'get'
  });
}
// 字典详情
export function dicListByCode(data) {
  return request({
    url: `/pc/sys/dic/listByCode`,
    method: 'post',
    data
  });
}
// 字典新增
export function dicAdd(data) {
  return request({
    url: '/pc/sys/dic/add',
    method: 'post',
    data
  });
}
// 字典删除/编辑
export function dicUpdate(data) {
  return request({
    url: '/pc/sys/dic/update',
    method: 'post',
    data
  });
}
// 导入
export function uploadExcel(data) {
  return request({
    url: '/documentInfo/uploadExcel',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  }, false);
}
// 头图列表
export function sysBannerList(data) {
  return request({
    url: '/pc/sysBanner/list',
    method: 'post',
    data
  });
}

// 头图新增
export function sysBannerUpload(data) {
  return request({
    url: '/pc/sysBanner/upload',
    method: 'post',
    data
  }, false);
}

// 头图编辑
export function sysBannerUpdate(data) {
  return request({
    url: '/pc/sysBanner/update',
    method: 'post',
    data
  }, false);
}
// 头图详情
  export function sysBannerDetail(data) {
    return request({
      url: '/pc/sysBanner/detail',
      method: 'post',
      data
    });
  }
  // 启停头图
  export function switchStatus(data) {
    return request({
      url: '/pc/sysBanner/switchStatus',
      method: 'post',
      data
    });
  }

// 上传图片
  export function uploadFile(data, onUploadProgress) {
    return request({
      url: '/pc/sysBanner/uploadFile',
      method: 'post',
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) { // onUploadProgress是上传进度
          onUploadProgress(progressEvent);
        }
      },
      data
    }, false);
  }
// 上传视频
  export function uploadVideo(data, onUploadProgress) {
    return request({
      url: '/pc/sysBanner/uploadVideoFile',
      method: 'post',
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) { // onUploadProgress是上传进度
          onUploadProgress(progressEvent);
        }
      },
      data
    }, false);
  }