import AES from '@utils/ar-aes/ar-aes';
const aesFun = AES.AES;

const encode = (str, key = 'hgaqxuxlfpj1s7c7', iv = 'kogapcv2k1w4x6x4') => {
  if (str) {
    str = JSON.stringify(str);
    str = aesFun.encrypt(str, key, iv);
    str = str.split('/').join('-');
    str = str.split('+').join('_');
    return str;
  } else {
    return null;
  }
};

const decode = (str, key = 'hgaqxuxlfpj1s7c7', iv = 'kogapcv2k1w4x6x4') => {
  if (str) {
    str = str.split('-').join('/');
    str = str.split('_').join('+');
    str = aesFun.decrypt(str, key, iv);
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  } else {
    return null;
  }
};

const aes = {
  encode,
  decode
};
export default aes;
