<template>
    <div class="headmap-detail">
      <headerTitle title="示例库管理" :titles="['详情']" :showBtn="true"></headerTitle>
          <div class="content">
              <p class="title">示例详情</p>
              <div class="infobox">
                    <el-form  ref="form" :model="form" label-width="100px">
                        <el-form-item label="问题标签：">
                            <el-select v-model="form.countryTag" placeholder="请选择问题标签" >
                                <el-option :disabled="isDetail==1" v-for="(item,index) in labelList" :key="index" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                            <el-input v-if="isDetail==1"   inline-block type="primary" class="saveBtns" v-model="form.questionTags"  readonly></el-input>
                            <el-input v-else @click.native="showClass" inline-block type="primary" class="saveBtns" v-model="form.questionTags"  readonly></el-input>
                        </el-form-item>
                        <el-form-item label="问题关键字：">
                            <el-input :disabled="isDetail==1" v-model="form.questionKey" placeholder="请输入问题关键字"></el-input>
                        </el-form-item>
                        <el-form-item label="问题示例：">
                            <el-input :disabled="isDetail==1" type="textarea" rows="4" resize="none" v-model="form.questionContent" placeholder="请输入问题示例"></el-input>
                        </el-form-item>
                        <el-form-item label="答案关键字：">
                            <el-input :disabled="isDetail==1" v-model="form.answerKey" placeholder="请输入答案关键字"></el-input>
                        </el-form-item>
                        <el-form-item label="答案示例：">
                            <el-input :disabled="isDetail==1" type="textarea" rows="4" resize="none" v-model="form.answerContent" placeholder="请输入答案示例"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" v-if="isDetail==2" class="saveBtn" @click="save">保存</el-button>
              </div>
              <el-dialog title="分类" :visible.sync="dialogVisibleClass" :show-close="false" center width="600px">
                <div class="classification">
                    <div v-for="(item,index) in typeList" :key="'type_' + index" v-show="item && item.childDict && item.childDict.length > 0">
                        <p class="typeName" >{{ item.name }}</p>
                        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                            <el-checkbox
                            border
                            v-for="(childItem, childIndex) in item.childDict"
                            :label="childItem.name"
                            :key="'checkbox_' + index + '_' + childIndex"
                           ></el-checkbox>
                            </el-checkbox-group>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="info" @click="dialogVisibleClass = false" round style="width: 45%;border-radius: 25px;">取消</el-button>
                    <el-button type="primary" @click="cancelType" round style="width: 45%;border-radius: 25px;">确定</el-button>
                </span>
                </el-dialog>
          </div>
    </div>
  </template>

  <script>
  import headerTitle from '@/components/title.vue';
  import { documentInfo, dicListByCode, getDictTreeByCode, documentInfoupdate } from 'api/index.js';
  export default {
      name: 'SamplebaseDetail',
      components: {
          headerTitle
      },
      data() {
          return {
              form: {
                  questionKey: '',
                  questionContent: '',
                  answerKey: '',
                  answerContent: '',
                  countryTag: ""
              },
              labelList: [],
              isDetail: 1,
              dialogVisibleClass: false,
              typeList: [],
              checkboxes: [
                  []
              ],
              checkedCities: [],

          };
      },
      mounted() {
        this.isDetail = this.$route.query.isDetail;
        this.getDetial(this.$route.query.id);
        this.getCodeList();
        this.getTypeList();
      },
      methods: {
        getDetial(ids) {
            const data = {
                id: ids
            };
            documentInfo(data).then((res) => {
                if (res.code == '2000') {
                    this.form = res.data;
                    this.checkedCities = this.form.questionTags.split(',');
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
                }
            });
        },
        getCodeList() {
            dicListByCode({ code: "GJFL" }).then(res => {
            if (res.code == '2000') {
              this.labelList = res.data;
            } else {
              this.$message({
                type: 'error',
                message: res.msg
                });
            }
          });
        },
        getTypeList() {
            getDictTreeByCode({ code: "QUESTION_TAG" }).then(res => {
                if (res.code == '2000') {
                    this.typeList = res.data;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
              }
            });
        },
        showClass() {
            this.dialogVisibleClass = true;
          },
          cancelType() {
            this.dialogVisibleClass = false;
            this.form.questionTags = this.checkedCities.join(',');
          },
          handleCheckedCitiesChange(value) {
                this.checkedCities = value;
                console.log(this.checkedCities);
          },
          save() {
            documentInfoupdate(this.form).then((res) => {
                if (res.code == '2000') {
                    this.$message({
                        type: 'success',
                        message: '保存成功'
                        });
                        this.getDetial(this.$route.query.id);
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                        });
                }
            });
          },
      }
  };
  </script>

  <style lang="stylus" scoped>
  .content{
      width :100%;
      padding-bottom:200px;
      background-color: #fff;
      border-radius: 10px;
      .title{
          width: 100%;
          padding: 15px 20px;
          box-sizing: border-box;
          margin-bottom: 30px;
          border-bottom: 1px solid #D9DBDE;
          color: #333333;
          font-size: 16px;
          margin-top: 0;
      }
  }
  .infobox{
      width: 50%;
      padding: 0 21px;
      box-sizing: border-box
  }
    .saveBtn{
      width :250px;
      height: 50px;
      line-height: 50px;
      margin-top: 23px;
      padding: 0;
      border-radius: 25px
    }
    .saveBtns{
        width :163px;
        margin-left: 30px;
    }
    .el-checkbox{
        margin-right: 10px;
        padding: 6px 10px 5px 5px;
        height :34px;
        margin-bottom: 10px
    }
    .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
        margin-left: 0
    }
    .dualog-footer{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
  </style>

