import router from '@/router';
export default {
  install(Vue) {
    Vue.prototype.checkPer = (value) => {
      if (value && typeof value == 'string') {
        const limit = router.app.$route && router.app.$route.meta && router.app.$route.meta.limits;
        if (limit.indexOf(value) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
  }
};
