<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepalive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepalive" />
  </div>
</template>
<script>
import logMethod from '@utils/logMethod.js';
export default {
  name: 'App',
  beforeDestroy() {
    // 关闭indexedDB数据库连接
    logMethod.closeDb();
  }
};
</script>

<style lang="stylus">
body, html, #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #333333;
}
</style>
