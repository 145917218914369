
<template>
  <div class="headmap-list">
    <headerTitle title="头图管理"></headerTitle>
    <div class="content">
        <search :searchObj="searchObj" @searchClick="searchClick" :statusList="statusList" @addVideo="addVideo" @addPicture="addPicture"></search>
        <el-table :data="dataList" style="width: 100%" v-loading="loading" stripe border>
        <el-table-column label="序号" align="center" :index="indexMethod" type="index" width="55"></el-table-column>
        <el-table-column prop="type" label="头图类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.type==1">视频</span>
                <span v-else>文章</span>
            </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"  align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="introduction" label="简介"  align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createName" label="创建人"  align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间"  align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="状态"  align="center" show-overflow-tooltip>
            <template slot-scope="scope">
            <span v-if="scope.row.status==0" style="color:#FF6C40">停用</span>
            <span v-else style="color:#2AB73A">启用</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
                <el-button size="small" type="text" @click="handleDetail(scope.row)">查看详情</el-button>
                <el-button size="small" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button size="small" type="text" @click="handleStatusChange(scope.row)">
                    <span v-if="scope.row.status==0" style="color:#2AB73A">启用</span>
                    <span v-else style="color:#FF6C40">停用</span>
                </el-button>
                <el-button size="small" type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
            </el-table-column>
            <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
        </el-table>
        <paging :pageall="pageAll" :pagenum="pageNum" :pagesize="pageSize" @pageclick="pageclick"></paging>
    </div>
    <!-- 删除 -->
    <el-dialog title="删除头图" :visible.sync="dialogVisible" :show-close="false" center width="410px">
        <span style="display: block; text-align: center;">请确认信息无误后操作，确定后不可取消</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="dialogVisible = false" round style="width: 46%;border-radius: 25px;">取消</el-button>
          <el-button type="primary" @click="cancel" round style="width: 46%;border-radius: 25px;">确定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import search from "./component/search.vue";
import { sysBannerList, switchStatus, sysBannerUpdate } from 'api/index.js';
import headerTitle from "../title.vue";
import paging from '../paging.vue';
export default {
    components: {
        search,
        headerTitle,
        paging
    },
    data() {
        return {
            dataList: [],
            loading: false,
            total: 0,
            page: 1,
            pageSize: 10,
            pagenum: 1,
            searchObj: {

            },
            statusList: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: 0,
                    label: '停用'
                },
                {
                    value: 1,
                    label: '启用'
                }
            ],
            dialogVisible: false,
            pageNum: 1,
            pageAll: 0,
            id: ""
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            const params = {
            ...this.searchObj
          };
          params.startDate = this.searchObj.time && this.searchObj.time[0] ? this.searchObj.time[0] : '';
          params.endDate = this.searchObj.time && this.searchObj.time[1] ? this.searchObj.time[1] : '';
          delete params.time;
          params.pageSize = this.pageSize;
          params.pageNum = this.pageNum;
          this.loading = true;
            sysBannerList(params).then((res) => {
                if (res.code == '2000') {
                    this.dataList = res.data.list;
                    this.pageAll = res.data.total;
                    this.loading = false;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  });
                }
            });
        },
         // 点击分页
         pageclick(val) {
            this.pageNum = val;
            this.getList();
          },
        searchClick() {
            this.pageclick(1);
        },
        handleDelete(row) {
            this.dialogVisible = true;
            this.id = row.id;
        },
        handleDetail(row) {
            this.$router.push({
                path: 'headmapDetail',
                query: {
                    id: row.id
                }
            });
        },
        handleEdit(row) {
            this.$router.push({
                path: 'Editheadmap',
                query: {
                    id: row.id
                  }
            });
        },
        indexMethod(index) {
            return index + 1 + (this.pagenum - 1) * this.pageSize;
        },
        cancel() {
            const data = {
                id: this.id,
                isDelete: 1
            };
            const datas = JSON.stringify(data);
            const blob = new Blob([datas], {
                type: 'text/plain'
            });
            const formData = new FormData();
            formData.append('file', blob);
            sysBannerUpdate(formData).then((res) => {
                if (res.code == '2000') {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                        });
                        this.dialogVisible = false;
                        this.getList();
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                        });
                }
            });
        },
        // 添加视频
        addVideo() {
            this.$router.push({
                path: 'Addheadmap',
                query: {
                    isVideo: 1
                  }
            });
        },
        // 添加图文
        addPicture() {
            this.$router.push({
                path: 'Addheadmap',
                query: {
                    isVideo: 0
                  }
            });
        },
        // 修改状态
        handleStatusChange(row) {
            const data = {
                id: row.id,
                status: row.status === 1 ? 0 : 1
            };
            switchStatus(data).then((res) => {
                if (res.code == '2000') {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                        });
                        this.getList();
                }
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
.headmap-list{

}
>>> .el-table__header th, >>> .el-table__header tr {
    background: #EBF5FF !important;
    font-size: 14px !important;
    color: #202224 !important;
  }

  >>> .el-table__body th, >>> .el-table__body tr {
    background: #fff !important;
    font-size: 14px !important;
    color: #202224 !important;
  }

  >>> .el-table__body tr .el-table__cell {
    padding: 8px 0 !important;
  }

  >>> .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #F5FAFF !important;
  }
  .content{
    width :100%;
    height: calc(85vh - 30px);
    background-color: #fff;
    border-radius: 10px;
    padding:20px;
    box-sizing: border-box
    }

</style>