<template>
  <div class="tableBox">
    <!-- 表格 -->
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column type="index" :index="indexMethod" label="排名" align="center"></el-table-column>
      <el-table-column prop="teacherName" label="销售名称" align="center"></el-table-column>
      <el-table-column prop="chatCount" label="回复条数" align="center"></el-table-column>
      <el-table-column prop="chatGroupCount" label="群数数量" align="center"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableBox',
  components: {},
  data() {
    return {};
  },
  props: {
    dataList: Array,
    pagenum: Number,
    pageSize: Number
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    }
  }
};
</script>
<style lang="stylus" scoped>
.tableBox {
  width: 100%;
}
</style>