import Vue from 'vue';
import Router from 'vue-router';
import routerFun from '@utils/routerFun.js';
// import {
//   component
// } from 'vue/types/umd';
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router);

const Login = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/login');
const home = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/Home');
const headmapList = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Headmapmanagement/headmapList');
const headmapDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Headmapmanagement/headmapDetail');
const Addheadmap = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Headmapmanagement/Addheadmap');
const Editheadmap = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Headmapmanagement/Editheadmap');
const SamplebaseList = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Samplebase/SamplebaseList');
const SamplebaseDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Samplebase/SamplebaseDetail');
const dictionartList = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/components/Dictionarymanagement/dictionartList');
const approvalManagement = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/approvalManagement/approvalManagement');
const applicationApprovalDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/approvalManagement/applicationApprovalDetail');
const processManagementDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/approvalManagement/processManagementDetail');
const accountManagement = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/accountManagement/accountManagement');
//
const customerInformationInput = () => import('@/views/customerService/customerInformationInput/customerInformationInput');
const customerInformationInputDetail = () => import('@/views/customerService/customerInformationInput/customerInformationInputDetail');
//
const customerInformation = () => import('@/views/administrators/customerInformation/customerInformation');
const customerInformationDetail = () => import('@/views/administrators/customerInformation/customerInformationDetail');
const statistics = () => import('@/views/administrators/statistics/statistics');
//
const customerFollow = () => import('@/views/sellers/customerFollow/customerFollow');
const customerFollowDetail = () => import('@/views/sellers/customerFollow/customerFollowDetail');
const customerFollowRecordDetail = () => import('@/views/sellers/customerFollow/customerFollowRecordDetail');
// const router = new Router({
//   mode: 'history',
const routes = [{
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    children: [{
        path: '/headmapList',
        name: 'headmapList',
        component: headmapList,
        meta: {
          title: '头图管理',
          limit: 'ttgl'
        }
      },
      {
        path: '/headmapDetail',
        name: 'headmapDetail',
        component: headmapDetail,
        meta: {
          title: '详情',
          limit: 'ttgl'
        }
      },
      {
        path: '/Addheadmap',
        name: 'Addheadmap',
        component: Addheadmap,
        meta: {
          title: '添加头图',
          limit: 'ttgl'
        }
      },
      {
        path: '/Editheadmap',
        name: 'Editheadmap',
        component: Editheadmap,
        meta: {
          title: '编辑头图',
          limit: 'ttgl'
        }
      },
      {
        path: '/SamplebaseList',
        name: 'SamplebaseList',
        component: SamplebaseList,
        meta: {
          title: '示例库管理',
          limit: 'slkgl'
        }
      },
      {
        path: '/SamplebaseDetail',
        name: 'SamplebaseDetail',
        component: SamplebaseDetail,
        meta: {
          title: '示例库管理详情',
          limit: 'slkgl'
        }
      },
      {
        path: '/dictionartList',
        name: 'dictionartList',
        component: dictionartList,
        meta: {
          title: '字典管理',
          limit: 'zdgl'
        }
      },
      {
        path: '/approvalManagement',
        name: 'approvalManagement',
        component: approvalManagement,
        meta: {
          title: '审批管理',
          limit: 'spgl'
        }
      },
      {
        path: '/applicationApprovalDetail',
        name: 'applicationApprovalDetail',
        component: applicationApprovalDetail,
        meta: {
          title: '申请详情',
          limit: 'spgl'
        }
      },
      {
        path: '/processManagementDetail',
        name: 'processManagementDetail',
        component: processManagementDetail,
        meta: {
          title: '审批管理',
          limit: 'spgl'
        }
      },
      {
        path: '/accountManagement',
        name: 'accountManagement',
        component: accountManagement,
        meta: {
          title: '账号管理',
          limit: 'zhgl'
        }
      },
      {
        path: '/customerInformationInput',
        name: 'customerInformationInput',
        component: customerInformationInput,
        meta: {
          title: '客户信息录入',
          limit: 'khxxlr'
        }
      },
      {
        path: '/customerInformationInputDetail',
        name: customerInformationInputDetail,
        component: customerInformationInputDetail,
        meta: {
          title: '客户信息录入',
          limit: 'khxxlr'
        }
      },
      {
        path: '/customerInformation',
        name: customerInformation,
        component: customerInformation,
        meta: {
          title: '客户信息',
          limit: 'khxx'
        }
      },
      {
        path: '/customerInformationDetail',
        name: customerInformationDetail,
        component: customerInformationDetail,
        meta: {
          title: '客户信息',
          limit: 'khxx'
        }
      },
      {
        path: '/statistics',
        name: statistics,
        component: statistics,
        meta: {
          title: '数据统计',
          limit: 'sjtj'
        }
      },
      {
        path: '/customerFollow',
        name: customerFollow,
        component: customerFollow,
        meta: {
          title: '客户跟进',
          limit: 'khgj'
        }
      },
      {
        path: '/customerFollowDetail',
        name: customerFollowDetail,
        component: customerFollowDetail,
        meta: {
          title: '客户跟进',
          limit: 'khgj',
        }
      },
      {
        path: '/customerFollowRecordDetail',
        name: customerFollowRecordDetail,
        component: customerFollowRecordDetail,
        meta: {
          title: '客户跟进详情',
          limit: 'khgjxq'
        }
      }
    ]
  }
];
// });

// 全局路由守卫
// router.beforeEach(function (to, from, next) {
//   if (sessionStorage.getItem('token')) { // 判断是否登录
//     next();
//   } else { // 没登录则跳转到登录界面
//     if (to.path === '/') {
//       next();
//     } else {
//       // next({
//       //   path: '/'
//       // });
//       next();
//     }
//   }
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
// });
export default routerFun(Router, routes);
