<template>
  <div class="applicationApproval">
    <!--  -->
    <applicationApprovalSearch :searchObj="searchObj" :statusList="statusList" @searchClick="searchClick"></applicationApprovalSearch>
    <!--  -->
    <el-table :cell-style="cellStyle" :data="dataList" style="width: 100%" v-loading="loading" stripe border>
      <el-table-column label="序号" align="center" :index="indexMethod" type="index" width="55"></el-table-column>
      <el-table-column prop="questionContent" label="问题示例" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="answerContent" label="答案示例" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="questionTags" label="问题标签" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createName" label="申请人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="statusName" label="申请状态" align="center" show-overflow-tooltip>
        <!-- <template slot-scope="scope"></template> -->
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handleDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageClick"></paging>
  </div>
</template>

<script>
import paging from '../../components/paging.vue';
import applicationApprovalSearch from './component/applicationApprovalSearch.vue';
import { getApprovalList } from 'api/approvalManagement.js';
export default {
  name: 'ApplicationApproval',
  components: {
    applicationApprovalSearch,
    paging
  },
  props: {
    activeName: String
  },
  watch: {
    activeName: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      immediate: true, // 进入页面后立即自动调用handler函数
      handler(newValue) {
        if (newValue == 'first') {
          this.getList();
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      searchObj: {},
      statusList: [
        { name: '申请中', code: 1 },
        { name: '申请通过', code: 2 },
        { name: '申请不通过', code: 3 }
      ],
      dataList: [],
      pagenum: 1,
      pageSize: 10,
      pageall: 0
    };
  },
  methods: {
    // 获取列表
    getList() {
      const params = {
        pageNum: this.pagenum,
        pageSize: this.pageSize,
        createTimeBegin: this.searchObj.time && this.searchObj.time[0] ? this.searchObj.time[0] : '',
        createTimeEnd: this.searchObj.time && this.searchObj.time[1] ? this.searchObj.time[1] : '',
        ...this.searchObj
      };
      delete params.time;
      this.loading = true;
      getApprovalList(params).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.dataList = res.data && res.data.list;
          this.pageall = res.data.total;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 搜索
    searchClick() {
      this.pageClick(1);
    },
    // 查看详情
    handleDetail(item) {
      this.$router.push({
        path: '/applicationApprovalDetail',
        query: {
          id: item.id
        }
      });
    },
    cellStyle(row) {
      if (row.column.label == '申请状态') {
        if (row.row.status == 1) {
          return 'color:#FF9200';
        } else if (row.row.status == 2) {
          return 'color:#00BD70';
        } else {
          return 'color:#FF5353';
        }
      }
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    pageClick(val) {
      this.pagenum = val;
      this.getList();
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .el-table__header th, >>> .el-table__header tr {
  background: #EBF5FF !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body th, >>> .el-table__body tr {
  background: #fff !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body tr .el-table__cell {
  padding: 8px 0 !important;
}

>>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #F5FAFF !important;
}
>>>.searchButton{
  margin-top: 8px;
}
</style>

