// import Cookies from './cookie';
import Config from '@/settings';

const TokenKey = Config.TokenKey;

export function getToken() {
  // return Cookies.getCookie(TokenKey);
  return sessionStorage.getItem(TokenKey);
};

export function setToken(token, rememberMe) {
  return sessionStorage.setItem(TokenKey, token);
  // if (rememberMe) {
  //   return Cookies.setCookie(TokenKey, token, {
  //     expires: Config.tokenCookieExpires
  //   });
  // } else {
  //   return Cookies.setCookie(TokenKey, token);
  // }
};

export function removeToken() {
  return sessionStorage.removeItem(TokenKey);
  // return Cookies.removeCookie(TokenKey);
};
