<template>
  <div class="searchBigBox">
    <div class="searchBox">
      <!-- 任务类型 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">任务类型：</div>
        <div class="searchInputBox">
          <el-select size="small" clearable v-model="searchObj.taskType" placeholder="请选择任务类型" @change="searchClick">
            <el-option v-for="(item, index) in taskType" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 资源状态 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">资源状态：</div>
        <div class="searchInputBox">
          <el-select size="small" clearable v-model="searchObj.customType" placeholder="请选择资源状态" @change="searchClick">
            <el-option v-for="(item, index) in resourceStatus" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 跟进状态 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">跟进状态：</div>
        <div class="searchInputBox">
          <el-select size="small" clearable v-model="searchObj.followUpStatus" placeholder="请选择跟进状态" @change="searchClick">
            <el-option v-for="(item, index) in followUpStatus" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 潜在客户 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">潜在客户：</div>
        <div class="searchInputBox">
          <el-input size="small" clearable v-model="searchObj.customName" placeholder="请输入潜在客户" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div>
      <!-- 手机号 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">手机号：</div>
        <div class="searchInputBox">
          <el-input size="small" clearable v-model="searchObj.customPhone" placeholder="请输入手机号" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div>
    </div>
    <div class="buttonBox">
      <div class="button">
        <el-button type="primary" size="small" @click="searchClick" round plain>查询</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data() {
    return {};
  },
  props: {
    searchObj: Object,
    followUpStatus: Array,
    resourceStatus: Array,
    taskType: Array
  },
  methods: {
    searchClick() {
      this.$emit('searchClick');
    },
    addClick() {
      this.$emit('addClick');
    },
    allocationClick() {
      this.$emit('allocationClick', 'multiple');
    },
    importClick() {
      this.$emit('importClick');
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBigBox {
  display: flex;
  justify-content: space-between;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 10px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;

    .button .el-button {
      width: 80px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-right: 11px;
    }
  }
}
</style>