<template>
  <div>
    <div id="growthChart" :style="{ height: height, width: width }"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'Graph',
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      immediate: true,
      handler(val) {
        // this.setOptions(val);
        console.log(val, 'val');
        this.$nextTick(() => {
          this.initChart();
        });
      }
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initChart();
    // });
  },
  methods: {
    loadings() {
      this.chart.showLoading({
        text: '统计中，请稍候...',
        maskColor: '#fff',
        textColor: '#000',
        color: 'rgba(89,137,247)'
      });
    },
    initChart() {
      const growthChart = document.getElementById('growthChart');
      this.chart = echarts.init(growthChart);
      this.loadings();
      this.setOptions(this.chartData);
    },
    setOptions({ counts, dates } = {}) {
      this.chart.hideLoading();
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates,
          axisLabel: {
            interval: 0,
            rotate: 40
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: counts,
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(142,177,255,1)'
                },
                {
                  offset: 1,
                  color: 'rgba(223,236,255,0.5)'
                }
              ])
            },
            smooth: true
          }
        ]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
<style lang="stylus" scoped></style>