class CommonTools {
  // 身份证脱敏
  static idCardFilter(idCard) {
    if (!idCard) return idCard;
    const idCard1 = idCard.toString();
    return idCard1.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1****\$2");
  }

  // 手机号脱敏
  static mobileFilter(phone) {
    if (!phone) return phone;
    const phone1 = phone.toString();
    var pho = /(\d{3})\d*(\d{4})/;
    return phone1.replace(pho, '$1****$2');
  }

  //
  static getBirthByIdCard(idCard) {
    if (idCard) {
      const birthday = idCard.substring(6, 10) + "-" + idCard.substring(10, 12) + "-" + idCard.substring(12, 14);
      return birthday;
    }
  }

  //
  static getSexByIdCard(idCard) {
    if (idCard) {
      if (parseInt(idCard.substr(16, 1)) % 2 === 1) {
        return "男";
      } else {
        return "女";
      }
    }
  }

  //
  static getAgeByIdCard(idCard) {
    if (idCard) {
      var ageDate = new Date();
      var month = ageDate.getMonth() + 1;
      var day = ageDate.getDate();
      var age = ageDate.getFullYear() - idCard.substring(6, 10) - 1;
      if (idCard.substring(10, 12) < month || (idCard.substring(10, 12) === month && idCard.substring(12, 14) <= day)) {
        age++;
      }
      if (age <= 0) {
        age = 1;
      }
      return age;
    }
  }

  //
  static getIdCardLast(idCard) {
    if (idCard) {
      const lastDigits = idCard.slice(-4);
      return lastDigits;
    }
  }

  //
  static formatTime(time, num) {
    var yy, mm, dd, hh, min, ss, week;
    var formatTime = '';
    if (time !== '') {
      time = new Date(parseInt(time));
      yy = time.getFullYear();
      mm = time.getMonth() + 1;
      if (mm < 10) {
        mm = '0' + mm;
      }
      dd = time.getDate();
      if (dd < 10) {
        dd = '0' + dd;
      }
      hh = time.getHours();
      if (hh < 10) {
        hh = '0' + hh;
      }
      min = time.getMinutes();
      if (min < 10) {
        min = '0' + min;
      }
      ss = time.getSeconds();
      if (ss < 10) {
        ss = '0' + ss;
      }
      if (num === 10 || num === 12) {
        week = CommonTools.getWeek(time.getDay());
      }
      switch (num) {
        case 0:
          formatTime = yy + mm + dd + hh + min + ss;
          break;
        case 1:
          formatTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss;
          break;
        case 2:
          formatTime = yy + '/' + mm + '/' + dd + ' ' + hh + ':' + min + ':' + ss;
          break;
        case 3:
          formatTime =
            yy + '年' + mm + '月' + dd + '日' + hh + '时' + min + '分' + ss + '秒';
          break;
        case 4:
          formatTime = yy + '-' + mm + '-' + dd;
          break;
        case 5:
          formatTime = yy + '_' + mm + '_' + dd + ' ' + hh + '_' + min + '_' + ss;
          break;
        case 6:
          formatTime = mm + '-' + dd;
          break;
        case 7:
          formatTime = hh + ':' + min + ':' + ss;
          break;
        case 8:
          formatTime = yy + '.' + mm + '.' + dd;
          break;
        case 9:
          formatTime = yy + '-' + mm + '-' + dd;
          break;
        case 10:
          formatTime = yy + '年' + mm + '月' + dd + '日 ' + week;
          break;
        case 11:
          formatTime = yy + '年' + mm + '月' + dd + '日 ';
          break;
        case 12:
          formatTime = week;
          break;
        case 13:
          formatTime = hh;
          break;
        case 14:
          formatTime = yy + '年' + mm + '月' + dd + '日 ' + ' ' + hh + ':' + min + ':' + ss;
          break;
      }
    }
    return formatTime;
  }

  //
  static getWeek(timedat) {
    var week;
    switch (timedat) {
      case 0:
        week = '星期日';
        break;
      case 1:
        week = '星期一';
        break;
      case 2:
        week = '星期二';
        break;
      case 3:
        week = '星期三';
        break;
      case 4:
        week = '星期四';
        break;
      case 5:
        week = '星期五';
        break;
      case 6:
        week = '星期六';
        break;
    }
    return week;
  }

  //
  static getDeFaultDate(num, type) {
    var date1 = '';
    var date2 = '';
    var Nowdate = new Date();
    var threeDaysAgo = new Date().getTime() - (num - 1) * 24 * 60 * 60 * 1000;
    // 今天
    const yearEnd = Nowdate.getFullYear();
    const monthEnd = Nowdate.getMonth() + 1 < 10 ? "0" + (Nowdate.getMonth() + 1) : Nowdate.getMonth() + 1;
    const todayEnd = Nowdate.getDate() < 10 ? "0" + Nowdate.getDate() : Nowdate.getDate();
    // num天前
    const yearState = new Date(threeDaysAgo).getFullYear();
    const monthState = new Date(threeDaysAgo).getMonth() + 1 < 10 ? '0' + (new Date(threeDaysAgo).getMonth() + 1) : new Date(threeDaysAgo).getMonth() + 1;
    const todayState = new Date(threeDaysAgo).getDate() < 10 ? '0' + new Date(threeDaysAgo).getDate() : new Date(threeDaysAgo).getDate();
    if (type === true) {
      date1 = `${yearState}-${monthState}-${todayState} 00:00:00`;
      date2 = `${yearEnd}-${monthEnd}-${todayEnd} 23:59:59`;
    } else {
      date1 = `${yearState}-${monthState}-${todayState}`;
      date2 = `${yearEnd}-${monthEnd}-${todayEnd}`;
    }
    return [date1, date2];
  }

  // 中文名校验
  static nameRuleCheck(value) {
    const chineseValidation = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
    if (value) {
      if (!chineseValidation.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // 手机号校验
  static phoneRuleCheck(value) {
    const mobileValidation = /^1[3456789]\d{9}$/;
    if (value) {
      if (!mobileValidation.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // 身份证校验
  static idcardRuleCheck(value) {
    const idcardValidation = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (value) {
      if (!idcardValidation.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // 微信号校验
  static wxNumberRuleCheck(value) {
    const wxNumberValidation = /^[a-zA-Z][a-zA-Z0-9_-]{5,19}$/;
    if (value) {
      if (!wxNumberValidation.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // 邮箱校验
  static emailRuleCheck(value) {
    const emailValidation = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
    if (value) {
      if (!emailValidation.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

export default CommonTools;
