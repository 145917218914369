import CryptoJS from 'crypto-js';
import {
  CACHE_KEY
} from '@utils/customConstant/index.js';

// des加密(前端自用)
const encryptDes = (message, key = CACHE_KEY) => {
  // message = message.toString();
  message = JSON.stringify(message);
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  let str = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encrypted.ciphertext.toString()));
  str = str.split('/').join('-');
  return str.split('+').join('_');
};

// des解密(前端自用)
const decryptDes = (message, key = CACHE_KEY) => {
  if (!message || message == 0 || Number(message)) {
    return message;
  }
  message = message.split('\r').join('');
  message = message.split('\n').join('');
  message = message.split('_').join('+');
  message = message.split('-').join('/');
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(message)
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  try {
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    console.error(err);
    return null;
  }
};

export {
  encryptDes as enc,
  decryptDes as dec
};
