export default {
  state: {
    asideDefaultActive: sessionStorage.getItem('asideDefaultActive') || ''
  },
  mutations: {
    callSetAsideDefaultActive(state, res) {
      state.asideDefaultActive = res;
    }
  },
  actions: {
    setAsideDefaultActive(context, res) {
      context.commit('callSetAsideDefaultActive', res);
    }
  }
};