/**
 * 缓存密钥 (des)
 */
export const CACHE_KEY = '892601111114051173d9457060b9547c';

/**
 * RSA非对称加密公钥
 */
export const RSA_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx1hySbKFgKXhKK6/
15wh2y0vQjvRddeGCQXTBcYvidAjORHqsVW9UxAf4gOLIvGFOKNyM0X84WWl
b3XkFy68rPAQDMMF4vo5btL6FfWMe0nJiaGoEA8Gr/MpEi9VJIQXh3EEr5YA
6i6TuU9+L1wSYwPzvuPBhNIgovn+9PQRnqrIpn0c7HYOkdZY+11ljgoRiflR
5f1uaOCG37yCIJTeeChE9FBfQ31jyDF1VVlay1npcrDJ1ekLzWenyVrfY8V8
ziYWNgCgNF3ICGSJJNJvPSGU/+kyR5rngctwWXJJ6/Rx+oIWSmB3cts70TAU
fnm6ElqEToxjfD0lSommRE/PuQIDAQAB
-----END PUBLIC KEY-----`;

/**
 * RSA解密私钥
 */
export const PRIVATE_KEY = `-----BEGIN PRIVATE KEY-----
MIIEowIBAAKCAQEApobakg6joRt6+zJpWI/77sF0pfXFIclGr3l4ayMQBPjw49
j1AI/lUkZogoOXC1egcm7aa53NjJEXC30OW0bG7GRYjKYjGQljTAeSL32qriG4
y0+hOtPlSAuTw+gLf0S2QoP9p13b4ogw9BilTQ85qpDWFUVWhf2+dk1CKkA1xg
dRn2qqvwy5UTuLpjBUINUoBT9ITZ92XQbxaJ9LoppProp9Gj737ngNJECP6BcO
2vSpkN1uOM/JuCEla0F/WmrsDw905WFNdCwrunDwjkBjIDwk7XOxA3qgcBBueN
fAgR7YM0KbR8B/jkihXbkMVwbG2bEDfHpirQOwkyNXaEyvfwIDAQABAoIBAAet
cPdJ6H5dg3qAVP4JyV41GhkqrxeDLZiae5RI7gyko4qFrqoDeFmmOFxID9csh4
+vD6/lexwPdmYHsnk/1p0AdVqqsDSPy72uHa3F1/AmStxIxBCvw0LikcH2+Uy5
UMPZTQEB4Ujc6ZDfcAOQ/6IfAlfBth8AV18h6bqD8la6XZEvCj0oWjcX2OaVHs
vP0iGA4nd+Xwm1Ow5YFAb4N1FxrMkK/n4rzik7quA057puE0D5mPlnO3b/2Jn1
3IkKdJNmqQBSIrmMhnKNTmDxJn28j59fYbI6azRFUWvUQjmbls9rilKbiQjv08
fS3kNmOWsezRvf5WzuhtG/ILU+SaECgYEA3cNv0ov2P0suJHZCsE626T+9NIq9
CDxXWppGbordoxvRUumSwFfTiaKSv7Z9Ko1f4RhLTxhd+0SNdlLEjWcJhUPdE+
VVU+Enu5YtbN7gQiRy06m5IekyzhkFBBhQu9eDWN0ubrFJonvwvfBK9/IswjgW
xKpxrR1Hu426Qup80yECgYEAwDxYxR6Tiw7mpDIr913SVd/Qc43xw1Am8iJtKp
zyv1OPgKJ6hbEi+05ZdYwmSdx9C1DN8UoycUaEw30afmR9mv9Iiwak0STv2ZOo
N59RQ9ug32X1LwOQcAqpf4vOpkn3pN6AIxX9OiVKkWZXvzi12wBkd51KpKF9Bt
U0rkfBzp8CgYBommQnmy9XIZ4tQr8oLKjoktErdHm/5b6KlRS91bC/3EI7asN3
CLZWSKIR3gFiTfc41N3I/ERwXiCf/GCMFlsOSjmAxf8epqi4OhlJLkP3zvt+za
Lu1PHK91A/YzzJrdld1E6vld6WzwF6hlrGY6wrHIvYzlD6iEIqvKpsH/ErIQKB
gHqD+29l1VLC5GuxLiQxP2RLiGadCIAaDTg0iK6n6G3Xq5Wn975M7qaLgKhR8f
UQ+QDFNvuY9h0LfIG6sa/sC8FFAc7ZJSHGELbyTDtm5jwBNb4rFOApvVwq72vC
hqmkaphA1hmGYiU0rxmLyFCuq/gvf9hb/r59zVkVeMW6JeKxAoGBAJsYyiPMhw
BBuMna98ShvBLPpzHTkyZO2tlcbpi0K9+pLIJhftTB3RtgEsM64qHCKoNFXW6Z
slwcDMy+8InbXDosU0JbUge7EJT9+xsuQlA/tt71d2XdN7yt/gXpZrkw6JK/8S
+EDceNXXYqVtzzMjlpuW2/H10QgphwktuDE1Ys

-----END PRIVATE KEY-----`;

/**
 * 登录时保存密码时的密钥
 */
export const LOGIN_KEY = '4fzw4ggkjc27tlxxo5v4oal1oqdkup8y'; // 登录时保存密码时的密钥

/**
 * headers写死的JYTAPP5
 */
// export const JYTAPP5 = 'yunzhensi';//
