import request from '@utils/request';

// 登录-密码登录
export function login(data) {
  return request({
    url: 'pc/sysUser/login',
    method: 'post',
    data
  });
}

// 获取验证码
export function getVerifyCode(data) {
  return request({
    url: 'pc/sysUser/getVerifyCode',
    method: 'post',
    data
  });
}
