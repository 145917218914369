export default {
  state: {
    socketSubscribeConfig: {
      czsrmyy: {
        XYdisplayName: '山西省长治市人民医院', // 小鱼用户名
        XYextId: '1423553ac70f5ebc76f1a315952f9da623f8bc13', // 小鱼用户Id
        XYclientId: "7QbFlo68guAdKJZa2l5aMRgg", // 小鱼初始化clientId
        XYclientSecret: "eMP52EhulvkdKx14ggcAglvAMZLwYfic" // 小鱼初始化clientSecret
      },
      czsfybjy: {
        XYdisplayName: '长治市妇幼保健院', // 小鱼用户名
        XYextId: 'a63e77e5b704e362a19738f70edd10d232374323', // 小鱼用户Id
        XYclientId: "sfeOLSrcXmhzlktrMBGNicvx", // 小鱼初始化clientId
        XYclientSecret: "VJ4Jfxde5dGNfQMbAJAAi7prw1IKmrL9" // 小鱼初始化clientSecret
      }
    },
    videoData: []
  },
  mutations: {
    updatevideoconfig(state, res) {
      if (res.videoflag) {
        let flag = true;
        for (let i = 0; i < state.videoData.length; i++) {
          if (state.videoData[i].confNumber == res.confNumber) {
            flag = false;
            break;
          }
        }
        if (flag) {
          state.videoData.push({
            confNumber: res.confNumber ? res.confNumber : '',
            password: res.password ? res.password : '',
            videoflag: res.videoflag
          });
        }
      } else {
        console.log(res);
        const item = state.videoData[res.dataIndex];
        item.videoflag = res.videoflag;
        let flag = true;
        for (let i = 0; i < state.videoData.length; i++) {
          if (state.videoData[i].videoflag) {
            flag = false;
            break;
          }
        }
        if (flag) {
          state.videoData = [];
        }
      }
    }
  },
  actions: {
    setvideoconfig(context, res) {
      context.commit('updatevideoconfig', res);
    }
  }
};