<template>
  <div>
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column type="index" label="序号" align="center"></el-table-column>
      <el-table-column prop="name" label="用户名称" align="center"></el-table-column>
      <el-table-column prop="username" label="账号" align="center"></el-table-column>
      <!-- <el-table-column prop="roleName" label="角色" align="center"></el-table-column> -->
      <el-table-column prop="expirationDate" label="有效时间至" align="center"></el-table-column>
      <el-table-column prop="isDelete" label="是否有效" align="center">
        <template slot-scope="scope">
          <span :style="`${scope.row.status === '1' ? 'color:red' : scope.row.status === '0' ? 'color: rgb(0, 255, 106)' : ''}`">
            {{ scope.row.status === '0' ? '有效' : scope.row.status === '1' ? '无效' : '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="updateClick(scope.row)">编辑</el-button>
          <el-button type="text" style="color: red" @click="deleteAccountClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'List',
  props: {
    dataList: Array
  },
  methods: {
    updateClick(item) {
      this.$emit('updateClick', item);
    },
    deleteAccountClick(item) {
      this.$confirm(`是否确认删除 "${item.name}" 的账号?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('deleteAccountClick', item);
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="stylus" scoped></style>