<template>
  <div class="elMenuBox" :style="{ backgroundColor: backgroundColor }">
    <!-- <div class="loginImg">
      <img class="hospitalIcon" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/images/cartSystem/aside/hospitalIcon.png" alt="" />
      <img class="yunzhenshiIcon" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/images/cartSystem/aside/yunzhenshiIcon.png" alt="" />
    </div> -->
    <el-menu :default-active="activeMenu" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :background-color="backgroundColor" text-color="#B2DBFF" active-text-color="#FFFFFF" :collapse="collapse" :router="false">
      <aside-components :pageList="pageList" :isSubpage="false" :collapse="collapse"></aside-components>
    </el-menu>
  </div>
</template>
<script>
import asideComponents from './asideComponents';
export default {
  name: 'Aside',
  props: {
    isSubpage: {
      type: Boolean,
      default: () => {
        return false;
      }
    }, // 是否是子页面
    parentIde: {
      type: String,
      default: () => {
        return '';
      }
    } // 父页面标识
  },
  data() {
    return {
      backgroundColor: '#3C7BEC',
      collapse: false,
      pageList: [
        {
          name: '头图管理',
          url: '/headmapList',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon01.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon1.png',
          limits: ['ttgl']
        },
        {
          name: '示例库管理',
          url: '/SamplebaseList',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon02.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon2.png',
          limits: ['slkgl']
        },
        {
          name: '审批管理',
          url: '/approvalManagement',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon03.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon3.png',
          limits: ['spgl']
        },
        {
          name: '账号管理',
          url: '/accountManagement',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon04.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon4.png',
          limits: ['zhgl']
        },
        {
          name: '字典管理',
          url: '/dictionartList',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon05.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon5.png',
          limits: ['zdgl']
        },
        {
          name: '客户信息录入',
          url: '/customerInformationInput',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon06.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon6.png',
          limits: ['khxxlr']
        },
        {
          name: '客户信息',
          url: '/customerInformation',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon06.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon6.png',
          limits: ['khxx']
        },
        {
          name: '数据统计',
          url: '/statistics',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon08.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon8.png',
          limits: ['sjtj']
        },
        {
          name: '客户跟进',
          url: '/customerFollow',
          icon: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon07.png',
          iconMask: 'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/icon7.png',
          limits: ['khgj']
        }
      ]
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  components: {
    asideComponents
  }
};
</script>
<style lang="stylus" scoped>
.loginImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 14px;
  padding: 30px;
}

.loginImg .hospitalIcon {
  width: 84px;
  height: 73px;
  margin-bottom: 10px;
}

.loginImg .yunzhenshiIcon {
  width: 117px;
  height: 19px;
}

.elMenuBox {
  height: 100%;
  overflow-y: auto;
}

.el-menu {
  border: none;
}

>>> .el-menu-item-group__title {
  padding: 0;
}

>>> .el-menu--collapse>div>div>.el-submenu>.el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

>>>.el-menu-item {
  border-left: solid 8px #3C7BEC;
}

>>>.el-menu-item.is-active {
  background: linear-gradient(90deg, #5B9DF8 0%, #3C7BEC 100%);
  border-left: solid 8px #9FC1FF;
}

>>>.el-submenu__title i {
  color: #fff;
}

/* 设置滚动条的样式 */
.elMenuBox::-webkit-scrollbar {
  width: 6px;
}

/* 滚动槽 */
.elMenuBox::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
.elMenuBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
}
</style>