<template>
  <div class="applicationApprovalDetailBox">
    <headerTitle title="审批管理" :titles="['申请审批', '申请详情']" :showBtn="true"></headerTitle>
    <div class="applicationApprovalDetail">
      <header>申请详情</header>
      <div class="detailBox">
        <div class="leftBox">
          <!--  -->
          <div class="lineBox">
            <div class="questionTitle">问题标签：</div>
            <div class="questionBox">
              <el-select v-model="dataObj.countryTag" disabled placeholder="请选择" style="margin-right: 2%">
                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              </el-select>
              <el-select v-model="dataObj.questionTags" disabled placeholder="请选择">
                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              </el-select>
            </div>
          </div>
          <!--  -->
          <div class="lineBox">
            <div class="questionTitle">问题关键字：</div>
            <div class="questionBox">
              <el-input type="textarea" disabled :rows="3" placeholder="请输入内容" v-model="dataObj.questionKey"></el-input>
            </div>
          </div>
          <!--  -->
          <div class="lineBox">
            <div class="questionTitle">问题示例：</div>
            <div class="questionBox">
              <el-input type="textarea" disabled :rows="3" placeholder="请输入内容" v-model="dataObj.questionContent"></el-input>
            </div>
          </div>
          <!--  -->
          <div class="lineBox">
            <div class="questionTitle">答案关键字：</div>
            <div class="questionBox">
              <el-input type="textarea" disabled :rows="3" placeholder="请输入内容" v-model="dataObj.answerKey"></el-input>
            </div>
          </div>
          <!--  -->
          <div class="lineBox">
            <div class="questionTitle">答案示例：</div>
            <div class="questionBox">
              <el-input type="textarea" disabled :rows="3" placeholder="请输入内容" v-model="dataObj.answerContent"></el-input>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="title">流程：</div>
          <!--  -->
          <div class="stepsBox">
            <div class="stepsItems" v-for="(item, index) in process" :key="index">
              <div class="itemsBox">
                <!--  -->
                <div class="heads">
                  <div class="leftHeads">
                    <div class="headIcon">
                      <img class="headImg" :src="item.approverHeadPhoto" alt="" />
                      <img v-if="item.approveStatus == 2" class="statusImg" src="@/assets/successIcon.png" alt="" />
                      <img v-else-if="item.approveStatus == 1 && dataObj.status == 1" class="statusImg" src="@/assets/loadingIcon.png" alt="" />
                      <img v-else-if="item.approveStatus == 3" class="statusImg" src="@/assets/errorIcon.png" alt="" />
                    </div>
                    <div class="headTitle">
                      {{ index == 0 ? item.approverTitle : item.approver }}
                      <span class="introduce" v-if="item.approveStatusName">（{{ item.approveStatusName }}）</span>
                    </div>
                  </div>
                  <div class="time">{{ item.approveTime }}</div>
                </div>
                <!--  -->
                <div :class="[index != process.length - 1 ? 'dashed' : '', 'bottomBox']">
                  <div class="introduce" v-if="item.approverTitle == '发起人'">{{ item.approver }}</div>
                  <div class="noPassReason" v-if="item.approveStatus == 3">{{ item.approveResult }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <footer v-if="dataObj.status == 1 && loginId == dataObj.approverId">
            <el-button class="btns" type="primary" plain round @click="openRefuseDialog">拒绝</el-button>
            <el-button class="btns" type="primary" round @click="openAgreeDialog">同意</el-button>
          </footer>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog title="同意申请" :visible.sync="agreeDialog" :show-close="false" center width="410px">
      <span style="display: block; text-align: center">确认通过申请吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="agreeDialog = false" round style="width: 46%">取消</el-button>
        <el-button type="primary" @click="sureAgree" round style="width: 46%">确定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog title="拒绝申请" :visible.sync="refuseDialog" :show-close="false" center width="410px">
      <div class="refuseInputBox"><el-input type="textarea" :rows="4" placeholder="请输入拒绝原因" v-model="reason"></el-input></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="refuseDialog = false" round style="width: 46%">取消</el-button>
        <el-button type="primary" @click="sureRefuseDialog" round style="width: 46%">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getApprovalDetail, approveApproval, rejectApproval } from 'api/approvalManagement.js';
import headerTitle from '@/components/title.vue';
export default {
  name: 'ApplicationApprovalDetail',
  components: {
    headerTitle
  },
  data() {
    return {
      id: '',
      process: [],
      dataObj: {},
      loginId: '',
      refuseDialog: false,
      agreeDialog: false,
      reason: ''
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.loginId = JSON.parse(sessionStorage.getItem('patientMsg')).id;
  },
  methods: {
    // 获取详情
    getDetail() {
      this.loading = true;
      getApprovalDetail({ id: this.id }).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.process = JSON.parse(res.data.process);
          this.dataObj = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    openRefuseDialog() {
      this.reason = '';
      this.refuseDialog = true;
    },
    openAgreeDialog() {
      this.agreeDialog = true;
    },
    sureAgree() {
      this.loading = true;
      approveApproval({ id: this.id, approverId: this.loginId }).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '操作成功！'
          });
          this.agreeDialog = false;
          this.getDetail();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    sureRefuseDialog() {
      if (!this.reason) {
        this.$message.error('拒绝原因不可为空');
        return;
      }
      this.loading = true;
      rejectApproval({ id: this.id, approverId: this.loginId, reason: this.reason }).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '操作成功！'
          });
          this.refuseDialog = false;
          this.getDetail();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.applicationApprovalDetail {
  width: 100%;
  min-height: calc(85vh - 50px);
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;

  header {
    padding: 16px 20px;
    font-size: 16px;
    border-bottom: 1px solid #D9DBDE;
  }

  .detailBox {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    min-height: 70vh;

    .leftBox {
      width: 55%;
      box-sizing: border-box;
      padding: 0 30px 0 0;
      border-right: 1px solid #D9DBDE;
      min-height: 100%;

      .lineBox {
        display: flex;
        margin-bottom: 20px;

        .questionTitle {
          width: 100px;
        }

        .questionBox {
          flex: 1;
          display: flex;
        }
      }
    }

    .rightBox {
      box-sizing: border-box;
      width: 42%;
      display: flex;
      flex-direction: column;

      .stepsBox {
        padding-top: 20px;
        flex: 1;

        .stepsItems {
          .itemsBox {
            font-size: 16px;
            color: #333333;

            .heads {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .leftHeads {
                display: flex;
                align-items: center;

                .headIcon {
                  width: 48px;
                  height: 48px;
                  position: relative;
                  margin-right: 15px;

                  .headImg {
                    width: 48px;
                    height: 48px;
                  }

                  .statusImg {
                    position: absolute;
                    right: -8px;
                    bottom: 0;
                    width: 16px;
                    height: 16px;
                  }
                }

                .headTitle {
                  .introduce {
                    color: #999999;
                  }
                }
              }

              .time {
                font-size: 14px;
                color: #666666;
              }
            }

            .bottomBox {
              width: 96%;
              display: flex;
              position: relative;
              left: 24px;
              padding-bottom: 20px;
              min-height: 30px;

              .introduce {
                color: #999999;
                font-size: 14px;
                padding-left: 39px;
              }

              .noPassReason {
                padding: 15px;
                font-size: 14px;
                background: #F8F8F8;
                border-radius: 12px;
                margin-top: 8px;
                width: calc(100% - 120px);
                position: absolute;
                left: 40px;
              }
            }

            .dashed {
              border-left: dashed 2px #ccc;
            }
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;

        .btns {
          width: 30%;
        }
      }
    }
  }
}
</style>

