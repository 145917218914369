export default {
  state: {
    hospitalCode: 'czsrmyy',
    hospitalName: '长治市人民医院',
    hospitalId: 1,
    patient: sessionStorage.getItem('patient'), // 默认就诊人
    cardNo: sessionStorage.getItem('cardMsg'), // 默认就诊卡
    patientList: JSON.parse(sessionStorage.getItem('patientList')), // 就诊人列表
    patientCardList: sessionStorage.getItem('patientCardList'), // 就诊卡列表
    uploadUrlSy: process.env.VUE_APP_JYKJ_HTTPS + '/common/uploadImageSY',
    uploadImage: process.env.VUE_APP_JYKJ_HTTPS + '/common/uploadImage',
    loginDialog: false
  },
  mutations: {
    callSetHospitalCode(state, res) {
      state.hospitalCode = res;
    },
    callSetHospitalName(state, res) {
      state.hospitalName = res;
    },
    callSetHospitalId(state, res) {
      state.hospitalId = res;
    },
    callSetPatient(state, res) {
      state.patient = res;
    },
    callSetCardNo(state, res) {
      state.cardNo = res;
    },
    callSetPatientList(state, res) {
      state.patientList = res;
    },
    callSetPatientCardList(state, res) {
      state.patientCardList = res;
    },
    callSetLoginDialog(state, res) {
      state.loginDialog = res;
    }
  },
  actions: {
    setHospitalCode(context, res) {
      context.commit('callSetHospitalCode', res);
    },
    setHospitalName(context, res) {
      context.commit('callSetHospitalName', res);
    },
    setHospitalId(context, res) {
      context.commit('callSetHospitalId', res);
    },
    setPatient(context, res) {
      context.commit('callSetPatient', res);
    },
    setCardNo(context, res) {
      context.commit('callSetCardNo', res);
    },
    setPatientList(context, res) {
      context.commit('callSetPatientList', res);
    },
    setPatientCardList(context, res) {
      context.commit('callSetPatientCardList', res);
    },
    setLoginDialog(context, res) {
      context.commit('callSetLoginDialog', res);
    }
  }
};