import {
  JSEncrypt
} from 'jsencrypt';
import {
  RSA_KEY,
  PRIVATE_KEY
} from '@utils/customConstant/index.js';

const encode = (str) => {
  const encryptor = new JSEncrypt(); // 新建JSEncrypt对象
  encryptor.setPublicKey(RSA_KEY); // 设置加密公钥
  // str = JSON.stringify(str);
  str = encryptor.encrypt(str);
  str = str.split('/').join('-');
  str = str.split('+').join('_');
  return str;
};

const decode = (str) => {
  const encryptor = new JSEncrypt(); // 新建JSEncrypt对象
  encryptor.setPrivateKey(PRIVATE_KEY); // 设置解密私钥
  str = str.split('_').join('+');
  str = str.split('-').join('/');
  str = encryptor.decrypt(str);
  return str;
};

const rsa = {
  encode,
  decode
};

export default rsa;
