// import devtools from 'devtools-detect'; //2023-10-11 后台要求先关闭正式环境控制台限制
import Config from '@/settings';
let endlessLoopStr = '';
let t = '';
// const executeTimeFun = ''; //2023-10-11 后台要求先关闭正式环境控制台限制
// 正式环境打开控制台执行死循环搞死浏览器 //2023-10-11 后台要求先关闭正式环境控制台限制
// function endlessLoop() {
//   clearTimeout(executeTimeFun);
//   executeTimeFun = setTimeout(() => {
//     if (devtools.isOpen && Config.forbiddenStr != endlessLoopStr) {
//       for (let i = 0; true; i++) {
//         console.error(i);
//       }
//     }
//   }, 500);
// }
export default function () {
  // development
  // production
  if (process.env.VUE_APP_JYKJ == 'production') {
    // endlessLoop(); //2023-10-11 后台要求先关闭正式环境控制台限制
    document.onkeydown = function (e) {
      if (Config.endlessLoopFlagStr.indexOf(e.key) != -1 && Config.forbiddenStr != endlessLoopStr) {
        clearTimeout(t);
        endlessLoopStr += e.key;
        if (Config.forbiddenStr != endlessLoopStr) {
          t = setTimeout(() => {
            endlessLoopStr = '';
          }, 2000);
        }
      } else if (Config.forbiddenStr == endlessLoopStr && t) {
        clearTimeout(t);
        t = '';
      }
    };
    window.addEventListener('devtoolschange', event => {
      // endlessLoop(); //2023-10-11 后台要求先关闭正式环境控制台限制
    });
  }
};
