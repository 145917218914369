import request from '@utils/request';

// 客户管理-获取客户列表
export const getCustomerManagementList = (data) => {
  return request({
    url: 'pc/customInfo/list',
    method: 'post',
    data
  });
};

// 客户管理-新增客户
export const addCustomer = (data) => {
  return request({
    url: 'pc/customInfo/add',
    method: 'post',
    data
  });
};

// 客户管理-客户详情
export const getCustomerDetail = (data) => {
  return request({
    url: 'pc/customInfo/detail',
    method: 'post',
    data
  });
};

// 客户管理-编辑客户
export const editCustomerDetail = (data) => {
  return request({
    url: 'pc/customInfo/update',
    method: 'post',
    data
  });
};

// 客户管理-批量分配
export const batchAlloc = (data) => {
  return request({
    url: 'pc/customInfo/batchAlloc',
    method: 'post',
    data
  });
};

// 客户跟进-获取跟进列表
export const getTaskList = (data) => {
  return request({
    url: 'pc/customTask/list',
    method: 'post',
    data
  });
};

// 客户跟进-获取未跟进数量
export const countByCheckFlagAndTeacherId = () => {
  return request({
    url: 'pc/customTask/countByCheckFlagAndTeacherId',
    method: 'get',
  });
};

// 客户跟进-新建跟进记录
export const addCustomFollowUpRecord = (data) => {
  return request({
    url: 'pc/customFollowUpRecord/add',
    method: 'post',
    data
  });
};

// 客户跟进-获取跟进记录
export const getCustomFollowUpRecord = (data) => {
  return request({
    url: 'pc/customFollowUpRecord/list',
    method: 'post',
    data
  });
};

// 客户跟进-获取跟进记录详情
export const getCustomFollowUpDetail = (data) => {
  return request({
    url: 'pc/customFollowUpRecord/detail',
    method: 'post',
    data
  });
};

// 客户跟进-数据统计
export const customStatistics = (data) => {
  return request({
    url: 'pc/customStatistics/growthTrend',
    method: 'post',
    data
  });
};

// 客户管理-批量导入
export const uploadExcel = (data) => {
  return request({
    url: 'pc/customInfo/importCustom',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  }, false);
};

// 客户管理-删除客户
export const customDelete = (data) => {
  return request({
    url: 'pc/customInfo/delete',
    method: 'post',
    data
  });
};

// 跟进记录详情-微信聊天记录
export const getChatRecord = (data) => {
  return request({
    url: 'pc/customFollowUpRecord/getChatRecord',
    method: 'post',
    data
  });
};

// 跟进记录详情-发送评价
export const addEvaluate = (data) => {
  return request({
    url: 'pc/customFollowUpRecord/addEvaluate',
    method: 'post',
    data
  });
};
