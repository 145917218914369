import stopmjs from 'stompjs';
import sockjs from '@/utils/sockjs.js';

export default {
  state: {
    socketOpenFlag: false, // socket是否开启
    socketUrl: process.env.VUE_APP_WS_API,
    sock: '',
    stomp: '', //
    socketSubscribeObj: {}, // socket 已订阅列表对象 // 键名为all是全局订阅   其他订阅键名为其他唯一值 // 键值中存储着取消订阅需要用到的方法及异常断开时重连需要用到的url与回调方法
    t: '',
    socketProtocol: 'wss',
    toBeSubscribedList: [], // 待订阅消息列表
    messageToBeSentList: [], // 待发送消息列表
    globalMessageProcessing: {} // 存储全局消息处理函数
  },
  mutations: {
    // 创建socket链接
    callInitsocket(state) {
      return new Promise((resolve, reject) => {
        if (!state.socketOpenFlag) {
          if (state.socketProtocol == 'wss') {
            state.sock = new WebSocket(state.socketUrl);
          } else {
            state.sock = sockjs(state.socketUrl);
          }
          state.stomp = stopmjs.over(state.sock);
          if (state.t) {
            clearTimeout(state.t);
          };
          state.sock.onerror = () => {
            state.t = setTimeout(() => {
              this.commit('callInitsocket');
            }, 10000);
          };
          state.stomp.heartbeat.outgoing = 60000;
          state.stomp.heartbeat.incoming = 60000;
          //
          state.stomp.connect({}, (res) => {
            // socket已连接
            state.socketOpenFlag = true;
            console.log('链接成功');
            // 监听链接关闭事件
            this.commit('callSocketClose');
            // 异常断开重连时重新订阅所有已订阅端口
            this.commit('callAnewSubscribe');
            // 订阅待订阅列表数据
            if (state.toBeSubscribedList && state.toBeSubscribedList.length) {
              for (let i = 0; i < state.toBeSubscribedList.length; i++) {
                this.commit('callSocketSubscribe', state.toBeSubscribedList[i]);
              }
              state.toBeSubscribedList = [];
            }
            // 发送待发送消息列表数据
            if (state.messageToBeSentList && state.messageToBeSentList.length) {
              for (let i = 0; i < state.messageToBeSentList.length; i++) {
                this.commit('callSend', state.messageToBeSentList[i]);
              }
              state.messageToBeSentList = [];
            }
          }, error => {
            console.log(error);
          });
        }
        resolve();
      });
    },
    // socket消息订阅
    callSocketSubscribe(state, res) {
      // res = {
      //  url:'订阅链接',
      //  fun:'接收到消息的回调方法',
      //  idType:'订阅的唯一键名(自定义任意值)'
      // }
      if (!state.socketOpenFlag) {
        state.toBeSubscribedList.push(res);
        return;
      }
      if (res.idType) {
        const returnObj = state.stomp.subscribe(res.url, res.fun);
        state.socketSubscribeObj[res.idType] = {
          url: res.url,
          fun: res.fun,
          returnObj
        };
      } else {
        console.error('缺少订阅键名!!!');
      }
    },
    // 取消socket消息订阅
    callUnSocketSubscribe(state, idType) {
      if (idType) {
        try {
          if (state.socketSubscribeObj[idType]) {
            const id = state.socketSubscribeObj[idType].returnObj.id;
            state.socketSubscribeObj[idType].returnObj.unsubscribe(id);
            state.socketSubscribeObj[idType] = null;
          };
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error('缺少取消订阅的键名!!!');
      }
    },
    // 发送消息
    callSend(state, res) {
      if (!state.socketOpenFlag) {
        state.messageToBeSentList.push(res);
        return;
      }
      try {
        if (res.data) {
          console.log(state.stomp);
          state.stomp.send(res.url, res.header, JSON.stringify(res.data));
        } else {
          console.error('缺少要发送的数据');
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 监听socket关闭事件
    callSocketClose(state) {
      console.log('消息关闭监听已添加');
      state.sock.onclose = (res) => {
        console.log(res);
        state.socketOpenFlag = false;
        if (res.code != 1000) {
          this.commit('callCloseSocket');
          setTimeout(() => {
            this.commit('callInitsocket');
          }, 500);
        };
      };
    },
    // 监听全局消息订阅
    callAllsocket(state, res) {
      try {
        const id = sessionStorage.getItem('doctorCode');
        if (id) {
          this.commit('callSocketSubscribe', {
            url: `/user/${id}/message/`,
            fun: (res) => {
              console.log('全局消息回调');
              console.log(res);
            },
            idType: 'all'
          });
        } else {
          console.error('未获取到全局监听id');
        }
      } catch (e) {
        console.error('全局监听添加失败↓↓↓');
        console.error(e);
      }
    },
    // 关闭socket连接
    callCloseSocket(state) {
      console.log(state.stomp);
      state.stomp.disconnect(() => {
        console.log('关闭socket连接');
        state.socketOpenFlag = false;
      });
      // state.sock.close();
    },
    // 异常断开重连时重新订阅所有已订阅端口
    callAnewSubscribe(state) {
      for (const key in state.socketSubscribeObj) {
        if (key != 'all' && state.socketSubscribeObj[key]) {
          this.commit('callSocketSubscribe', {
            url: state.socketSubscribeObj[key].url,
            fun: state.socketSubscribeObj[key].fun,
            idType: key
          });
        };
      };
    },
    // 绑定全局消息处理函数
    callSetGlobalMessageProcessing(state, res) {
      const obj = state.globalMessageProcessing;
      obj[res.key] = res.value;
      state.globalMessageProcessing = obj;
    }
  },
  actions: {
    // 创建socket链接
    initsocket(context, res) {
      context.commit('callInitsocket');
    },
    // 添加消息订阅
    socketSubscribe(context, res) {
      context.commit('callSocketSubscribe', res);
    },
    // 取消消息订阅
    unSocketSubscribe(context, idType) {
      context.commit('callUnSocketSubscribe', idType);
    },
    // 发送消息
    send(context, res) {
      context.commit('callSend', res);
    },
    // 关闭socket连接
    closeSocket(context) {
      context.commit('callCloseSocket');
    },
    // 绑定全局消息处理函数
    setGlobalMessageProcessing(context, res) {
      context.commit('callSetGlobalMessageProcessing', res);
    }
  }
};
