<template>
  <div class="statisticsBox" v-loading="loading">
    <headerTitle title="数据统计"></headerTitle>
    <div class="statistics">
      <div class="searchBox">
        <div class="searchInputBigBox">
          <div class="searchInputLabel">时间段：</div>
          <div class="searchInputBox"><el-date-picker @change="pageclick(1)" clearable v-model="searchObj.time" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
        </div>
      </div>
      <div class="statisticsChart">
        <div class="lineBox">
          <div class="leftBox">
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1.png" alt="" />
              <span>客户增长趋势</span>
            </div>
            <graph :chartData="chartData1"></graph>
          </div>
          <div class="rightBox">
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon4.png" alt="" />
              <span>客户资源情况</span>
            </div>
            <histogram :chartData="chartData2"></histogram>
          </div>
        </div>
        <div class="lineBox">
          <div class="leftBox">
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon2.png" alt="" />
              <span>客户分配情况</span>
            </div>
            <circularDiagram :chartData="chartData3" :threeTotal="threeTotal"></circularDiagram>
          </div>
          <div class="rightBox">
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon3.png" alt="" />
              <span>客户来源</span>
            </div>
            <circularDiagrams :chartData="chartData4"></circularDiagrams>
          </div>
        </div>
        <div class="lineBox">
          <div>
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon5.png" alt="" />
              <span>销售服务群数排名</span>
            </div>
            <tableBox :dataList="dataList" :pagenum="pagenum" :pageSize="pageSize"></tableBox>
            <!-- 分页 -->
            <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick"></paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { customStatistics } from 'api/customerManagement.js';
import paging from '../../../components/paging.vue';
import headerTitle from '../../../components/title.vue';
import tableBox from './component/table.vue';
import circularDiagram from './component/circularDiagram.vue';
import circularDiagrams from './component/circularDiagrams.vue';
import graph from './component/graph.vue';
import histogram from './component/histogram.vue';
export default {
  name: 'Statistics',
  components: { paging, headerTitle, circularDiagram, circularDiagrams, graph, histogram, tableBox },
  data() {
    return {
      loading: false,
      searchObj: {
        time: []
      },
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      dataList: [],
      chartData1: {},
      chartData2: {},
      chartData3: [],
      chartData4: [],
      threeTotal: 0,
      flag: true
    };
  },
  props: {},
  watch: {},
  computed: {},
  created() {
    this.searchObj.time = this.$utils.getDeFaultDate(7);
    this.getData();
  },
  mounted() {},
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getData();
    },
    getData() {
      const data = {
        startDate: this.searchObj.time && this.searchObj.time[0] ? this.searchObj.time[0] : '',
        endDate: this.searchObj.time && this.searchObj.time[1] ? this.searchObj.time[1] : '',
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum
      };
      this.loading = true;
      customStatistics(data).then(res => {
        this.loading = false;
        if (res.code == '2000') {
          const chartData3 = [];
          const chartData4 = [];
          res.data.three.forEach(item => {
            chartData3.push({
              value: item.y,
              name: item.x
            });
          });
          res.data.four.forEach(item => {
            chartData4.push({
              value: item.y,
              name: item.x
            });
          });
          this.chartData1 = res.data.one;
          this.chartData2 = res.data.two;
          this.threeTotal = res.data.threeTotal;
          this.chartData3 = chartData3;
          this.chartData4 = chartData4;
          this.dataList = (res.data.five && res.data.five.list) || [];
          this.pageall = (res.data.five && res.data.five.total) || 0;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.statistics {
  padding: 20px;
  box-sizing: border-box;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 20px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .lineBox {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding-bottom: 14px;
      border-bottom: solid 1px #EEEEEE;
      margin-bottom: 20px;

      img {
        margin-right: 10px;
      }
    }

    >div {
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
    }

    .leftBox, .rightBox {
      width: 49%;
    }
  }
}
</style>