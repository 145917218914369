<template>
  <div class="jyt-container">
    <el-container direction="vertical" class="bodyContainer">
      <el-header class="header">
        <Header></Header>
      </el-header>
      <el-container class="dispalyflex">
        <Aside class="aside" />
        <el-main class="main">
          <router-view :key="$route.fullPath" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Header from '../components/header';
import Aside from '../components/aside/aside';
// import { api } from "@/api/api";
// import Cookies from 'js-cookie'
export default {
  components: {
    Header,
    Aside
  },
  data() {
    return {
      tableData: [],
      active: false,
      isTrue: false,
      isChangePass: false,
      ChangePass: false,
      phone: '',
      showTime: true,
      sendTime: '',
      timer: null,
      Verification: '',
      newPass: '',
      Confirmpassword: '',
      editPass: false,
      isSuccess: false,
      showCabinet: false
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.phone = sessionStorage.getItem('oaAccount');
  },
  methods: {}
};
</script>

<style lang="stylus" scoped>
.jyt-container {
  width: 100%;
  height: 100%;

  .el-container {
    width: 100%;
    height: 100%;
  }
}

.header {
  height: 60px !important;
  width: 100%;
  padding: 0;
}

.activebox {
  position: relative;

  .collapse {
    position: absolute;
    top: 40%;
    display: inline-block;
    width: 15px;
    height: 40px;
    line-height: 40px;
    background: #ccc;
    color: #fff;
    text-align: center;
    z-index: 100;
    cursor: pointer;
  }

  .ac_collapse_right {
    right: -15px;
  }

  .ac_collapse_left {
    right: 0;
  }
}

.aside {
  width: 225px !important;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background-color: #303243;
}

.active {
  width: 15px !important;
}

.dispalyflex {
  display: flex;
  background-color: #f8f8f8;
  height: calc(100vh - 60px) !important;
}

>>>.el-main {
  padding: 0 !important;
}

.main {
  flex: 1;
  box-sizing: border-box;
  margin: 20px;

  .main_box {
    width: 100%;
    height: 85vh;
    background: #fff;
    padding-top: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .main_box_gh {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    height: 850px;
  }
}

::v-deep .el-dialog__footer {
  text-align: center !important;
}

.login-container {
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  span {
    color: red;
    display: block;
    margin-bottom: 20px;
    line-height: 24px;
  }
}

.success h3 {
  font-size: 20px;
  text-align: center;
  margin: 35px auto 40px;
  color: #333;
}
</style>