import request from '@utils/request';

// 申请审批 // 获取列表
export const getApprovalList = (data) => {
  return request({
    url: 'documentInfo/page',
    method: 'post',
    data
  });
};

// 申请审批 // 获取详情
export const getApprovalDetail = (data) => {
  return request({
    url: 'documentInfo/get',
    method: 'post',
    data
  });
};

// 申请审批 // 审批同意
export const approveApproval = (data) => {
  return request({
    url: 'documentInfo/approve',
    method: 'post',
    data
  });
};

// 申请审批 // 审批拒绝
export const rejectApproval = (data) => {
  return request({
    url: 'documentInfo/reject',
    method: 'post',
    data
  });
};

// 流程管理 // 获取列表
export const getDocumentProcessList = (data) => {
  return request({
    url: 'documentProcess/page',
    method: 'post',
    data
  });
};

// 流程管理 // 获取详情
export const getDocumentProcessDetail = (data) => {
  return request({
    url: 'documentProcess/get',
    method: 'post',
    data
  });
};

// 流程管理 // 获取审批人列表
export const getApprovalUserList = (data) => {
  return request({
    url: 'pc/sysUser/list',
    method: 'post',
    data
  });
};

// 流程管理 // 修改
export const updateDocumentProcess = (data) => {
  return request({
    url: 'documentProcess/update',
    method: 'post',
    data
  });
};
