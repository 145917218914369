import Vue from 'vue';
import Vuex from 'vuex';
import socket from './modules/socket';
import drawingApplication from './modules/drawingApplication';
import video from './modules/video';
import aside from './modules/aside';
import path from './modules/path';
import publicStore from './modules/publicStore';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    socket,
    drawingApplication,
    video,
    aside,
    path,
    publicStore
  }
});
