<template>
  <div class="processManagementDetailBox">
    <headerTitle title="流程管理" :showBtn="true"></headerTitle>
    <div class="processManagementDetail">
      <header>示例审批流程</header>
      <div class="detailBox">
        <!--  -->
        <div class="stepsBox">
          <div class="stepsItems" v-for="(item, index) in process" :key="index">
            <div class="itemsBox">
              <!--  -->
              <div class="heads">
                <div class="leftHeads">
                  <div class="headIcon">
                    <img class="headImg" :src="item.approverHeadPhoto" alt="" />
                  </div>
                  <div class="headTitle">{{ item.approverTitle }}</div>
                </div>
              </div>
              <!--  -->
              <div :class="[index != process.length - 1 ? 'dashed' : '', 'bottomBox']">
                <el-select style="margin-left: 20px;" v-if="item.approverTitle != '发起人'" v-model="item.approver" placeholder="请选择用户" @change="val => selectChange(val, index)">
                  <el-option v-for="items in options" :key="items.id" :label="items.name" :value="items.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <el-button type="primary" round style="width: 200px" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocumentProcessDetail, getApprovalUserList, updateDocumentProcess } from 'api/approvalManagement.js';
import headerTitle from '@/components/title.vue';
export default {
  name: 'ProcessManagementDetail',
  components: {
    headerTitle
  },
  data() {
    return {
      id: '',
      process: [],
      dataObj: {},
      options: []
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.getUserList();
  },
  methods: {
    // 获取详情
    getDetail() {
      this.loading = true;
      getDocumentProcessDetail({ id: this.id }).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.process = JSON.parse(res.data.process);
          this.dataObj = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 获取审批人列表
    getUserList() {
      this.loading = true;
      getApprovalUserList({ typeList: [0, 2] }).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.options = res.data.list;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    selectChange(val, index) {
      this.options.forEach(item => {
        if (item.id == val) {
          this.process[index].approver = item.name;
          this.process[index].approverId = item.id;
        }
      });
    },
    // 修改
    save() {
      const params = {
        id: this.dataObj.id,
        name: this.dataObj.name,
        process: JSON.stringify(this.process)
      };
      this.loading = true;
      updateDocumentProcess(params).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '操作成功！'
          });
          this.getDetail();
          this.getUserList();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.processManagementDetail {
  width: 100%;
  min-height: calc(85vh - 50px);
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;

  header {
    padding: 16px 20px;
    font-size: 16px;
    border-bottom: 1px solid #D9DBDE;
  }

  .detailBox {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    min-height: 65vh;
  }

  .stepsBox {
    padding-top: 20px;
    flex: 1;

    .stepsItems {
      .itemsBox {
        font-size: 16px;
        color: #333333;

        .heads {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .leftHeads {
            display: flex;
            align-items: center;

            .headIcon {
              width: 48px;
              height: 48px;
              position: relative;
              margin-right: 15px;

              .headImg {
                width: 48px;
                height: 48px;
              }
            }
          }
        }

        .bottomBox {
          width: 96%;
          display: flex;
          position: relative;
          left: 24px;
          padding: 10px 0 0 15px;
          min-height: 60px;
        }

        .dashed {
          border-left: dashed 2px #ccc;
        }
      }
    }
  }
}
</style>

