import request from '@utils/request';

// 账号管理 // 获取账号列表
export const getAccountManagementList = (data) => {
  return request({
    url: 'pc/sysUser/list',
    method: 'post',
    data
  });
};

// 账号管理 // 获取角色列表
export const getRoleList = (data) => {
  return request({
    url: 'pc/sysRole/list',
    method: 'post',
    data
  });
};

// 账号管理 // 新增账号提交
export const submitAddAccount = (data) => {
  return request({
    url: 'pc/sysUser/add',
    method: 'post',
    data
  });
};

// 账号管理 // 编辑账号提交
export const submitUpdateAccount = (data) => {
  return request({
    url: 'pc/sysUser/update',
    method: 'post',
    data
  });
};

// 账号管理 // 获取账号信息详情
export const getAccountData = (data) => {
  return request({
    url: 'pc/sysUser/detail',
    method: 'post',
    data
  });
};

// 账号管理 // 删除账号
export const deleteAccount = (data) => {
  return request({
    url: 'pc/sysUser/detail',
    method: 'post',
    data
  });
};
//
export const uploadExcel = (data) => {
  return request({
    url: 'pc/sysUser/importUser',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  }, false);
};
