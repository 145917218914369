<template>
    <div class="searchBigBox">
      <div class="searchBox" style="">
        <div class="inputBigBox">
          <div class="inputText">标题：</div>
          <el-input class="input" placeholder="请输入标题" v-model="searchObj.title" @keyup.enter="searchClick"></el-input>
        </div>
        <div class="inputBigBox">
          <div class="inputText">头图状态：</div>
          <div class="inputBox">
            <el-select v-model="searchObj.status" placeholder="" @change="searchClick" clearable>
              <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="inputBigBox">
          <div class="inputText">简介：</div>
          <el-input class="input" placeholder="请输入简介" v-model="searchObj.introduction" @keyup.enter="searchClick"></el-input>
        </div>

      </div>
      <div class="searchButton">
          <el-button type="primary" size="mini" plain round @click="searchClick">查询</el-button>
          <el-button type="primary" size="mini"  round @click="addPicture">添加图文</el-button>
          <el-button type="primary" size="mini"  round @click="addVideo">添加视频</el-button>
        </div>
      <div class="searchBox">
        <div class="inputBigBox">
          <div class="inputText">创建人：</div>
          <el-input class="input" clearable placeholder="请输入创建人" v-model="searchObj.createName" @keyup.enter="searchClick"></el-input>
        </div>
        <div class="inputBigBox">
          <div class="inputText">创建时间：</div>
          <el-date-picker :picker-options="setDateRange" clearable @change="searchClick" v-model="searchObj.time" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </div>
      </div>
    </div>
  </template>
  <script>
    export default {
      name: 'Search',
      props: {
        searchObj: Object,
        statusList: Array,
      },
      data() {
        return {
          setDateRange: {
          disabledDate: time => {
            // 禁用今天之后的日期【当前天可选】
            return time.getTime() > Date.now() + 86400000 - 5;
          }
        },
          selectedOptions: [],
        };
      },
      mounted() {

      },
      methods: {
        addPicture() {
          this.$emit('addPicture');
        },
        searchClick() {
          this.$emit('searchClick');
        },
        addVideo() {
          this.$emit('addVideo');
        }

      }
    };
  </script>
  <style lang="stylus" scoped>
    .searchBigBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 0 20px;

      .searchBox {
        display: flex;
        margin-bottom: 16px;
        align-items: center;

        .inputBigBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 56px;

          .input {
            width: 150px;
          }

          .inputText {
            font-size: 14px;
            color: #333;
            margin-right: 6px;
          }

          .inputBox {
            width: 166px;
          }

          .inputButton {
            width: 36px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #409EFF;
            font-size: 16px;
            color: #fff;
            border-radius: 0 8px 8px 0;
            cursor: pointer;
          }
        }
      }

      .searchButton {
        display: flex;
        margin-top: -11px;
        .el-button {
          padding: 0 !important;
          width: 80px;
          height: 28px;
          line-height: 28px;
        }
      }
    }
  </style>