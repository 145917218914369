<template>
  <div>
    <div id="resourceChart" :style="{ height: height, width: width }"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'Histogram',
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      immediate: true,
      handler(val) {
        // this.setOptions(val);
        this.$nextTick(() => {
          this.initChart();
        });
      }
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initChart();
    // });
  },
  methods: {
    loadings() {
      this.chart.showLoading({
        text: '统计中，请稍候...',
        maskColor: '#fff',
        textColor: '#000',
        color: 'rgba(89,137,247)'
      });
    },
    initChart() {
      const resourceChart = document.getElementById('resourceChart');
      this.chart = echarts.init(resourceChart);
      this.loadings();
      this.setOptions(this.chartData);
    },
    setOptions({ counts, dates } = {}) {
      this.chart.hideLoading();
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        // dataZoom: [
        //   {
        //     type: 'slider',
        //     show: true,
        //     start: 0,
        //     end: 30,
        //     xAxisIndex: [0]
        //   }
        // ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            },
            data: dates,
            axisLabel: {
              interval: 0,
              rotate: 40
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'bar',
            // showBackground: true,
            data: counts,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#7ACAFF' },
                { offset: 1, color: '#8E89FF' }
              ]),
              barBorderRadius: [15, 15, 0, 0]
            },
            barWidth: '30',
            showBackground: true,
            backgroundStyle: {
              color: '#F6F6F6'
            }
          }
        ]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
<style lang="stylus" scoped></style>