<template>
  <div class="approvalManagementBox">
    <headerTitle title="审批管理"></headerTitle>
    <div class="approvalManagement">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="申请审批" name="first">
          <applicationApproval :activeName="activeName"></applicationApproval>
        </el-tab-pane>
        <el-tab-pane label="流程管理" name="second">
          <processManagement :activeName="activeName"></processManagement>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import processManagement from './processManagement.vue';
import applicationApproval from './applicationApproval.vue';
export default {
  name: 'ApprovalManagement',
  components: {
    headerTitle,
    processManagement,
    applicationApproval
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick() {}
  }
};
</script>

<style lang="stylus" scoped>
.approvalManagement {
  width: 100%;
  min-height: calc(85vh - 50px);
  background-color: #fff;
  border-radius: 10px;
  padding:5px 20px 20px;
  box-sizing: border-box;
}
>>>.el-tabs__nav-wrap::after{
  height: 1px;
}
</style>

