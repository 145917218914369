<template>
  <div class="processManagement">
    <processManagementSearch :searchObj="searchObj" @searchClick="searchClick"></processManagementSearch>
    <!--  -->
    <el-table :data="dataList" style="width: 100%" v-loading="loading" stripe border>
      <el-table-column label="序号" align="center" :index="indexMethod" type="index" width="55"></el-table-column>
      <el-table-column prop="name" label="流程名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createName" label="创建人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handleDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageClick"></paging>
  </div>
</template>

<script>
import paging from '../../components/paging.vue';
import processManagementSearch from './component/processManagementSearch.vue';
import { getDocumentProcessList } from 'api/approvalManagement.js';
export default {
  name: 'ProcessManagement',
  components: {
    processManagementSearch,
    paging
  },
  props: {
    activeName: String
  },
  watch: {
    activeName: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      handler(newValue) {
        if (newValue == 'second') {
          this.getList();
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      searchObj: {},
      dataList: [],
      pagenum: 1,
      pageSize: 10,
      pageall: 0
    };
  },
  methods: {
    getList() {
      const params = {
        pageNum: this.pagenum,
        pageSize: this.pageSize,
        createTimeBegin: this.searchObj.time && this.searchObj.time[0] ? this.searchObj.time[0] : '',
        createTimeEnd: this.searchObj.time && this.searchObj.time[1] ? this.searchObj.time[1] : '',
        ...this.searchObj
      };
      delete params.time;
      this.loading = true;
      getDocumentProcessList(params).then(res => {
        this.loading = false;
        if (res.code == 2000) {
          this.dataList = res.data && res.data.list;
          this.pageall = res.data.total;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 搜索
    searchClick() {
      this.pageClick(1);
    },
    // 查看详情
    handleDetail(item) {
      this.$router.push({
        path: '/processManagementDetail',
        query: {
          id: item.id
        }
      });
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    pageClick(val) {
      this.pagenum = val;
      this.getList();
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .el-table__header th, >>> .el-table__header tr {
  background: #EBF5FF !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body th, >>> .el-table__body tr {
  background: #fff !important;
  font-size: 14px !important;
  color: #202224 !important;
}

>>> .el-table__body tr .el-table__cell {
  padding: 8px 0 !important;
}

>>> .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #F5FAFF !important;
}
</style>

