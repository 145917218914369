import {
  enc,
  dec
} from '@utils/encryptedFile/des.js';
const localStoragePro = Object.getPrototypeOf(localStorage);
const sessionStoragePro = Object.getPrototypeOf(sessionStorage);
// 重写localStorage,sessionStorage
const localCaching = (function () {
  // 重写设置localStorage原型
  Object.setPrototypeOf(localStorage, {
    setItem: localStoragePro.setItem,
    getItem: localStoragePro.getItem,
    removeItem: localStoragePro.removeItem,
    clear: localStoragePro.clear,
    key: localStoragePro.key,
    localStorageSet: localStoragePro.setItem,
    localStorageGet: localStoragePro.getItem,
    localStorageRemove: localStoragePro.removeItem
  });
  // 重写设置sessionStorage原型
  Object.setPrototypeOf(sessionStorage, {
    setItem: sessionStoragePro.setItem,
    getItem: sessionStoragePro.getItem,
    removeItem: sessionStoragePro.removeItem,
    clear: sessionStoragePro.clear,
    key: sessionStoragePro.key,
    sessionStorageSet: sessionStoragePro.setItem,
    sessionStorageGet: sessionStoragePro.getItem,
    sessionStorageRemove: sessionStoragePro.removeItem
  });
  // 重写localStorage.setItem方法
  localStorage.setItem = (name, value) => {
    localStorage.localStorageSet(enc(name), enc(JSON.stringify(value)));
  };
  // 重写localStorage.getItem方法
  localStorage.getItem = (name) => {
    return JSON.parse(dec(localStorage.localStorageGet(enc(name))));
  };
  // 重写localStorage.removeItem方法
  localStorage.removeItem = (name) => {
    return localStorage.localStorageRemove(enc(name));
  };
  // 重写sessionStorage.setItem方法
  sessionStorage.setItem = (name, value) => {
    sessionStorage.sessionStorageSet(enc(name), enc(JSON.stringify(value)));
  };
  // 重写sessionStorage.getItem方法
  sessionStorage.getItem = (name) => {
    return JSON.parse(dec(sessionStorage.sessionStorageGet(enc(name))));
  };
  // 重写sessionStorage.removeItem方法
  sessionStorage.removeItem = (name) => {
    return sessionStorage.sessionStorageRemove(enc(name));
  };
})();

export default localCaching;
