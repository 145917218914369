export default {
  state: {
    // toPath: sessionStorage.getItem('toPath') || ''
  },
  mutations: {
    // callSetToPath(state, res) {
    //   state.toPath = res;
    // }
  },
  actions: {
    // setToPath(context, res) {
    //   context.commit('callSetToPath', res);
    // }
  }
};
