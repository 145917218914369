// 姓名--中文
export const chineseValidation = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
// 手机号
export const mobileValidation = /^1[3456789]\d{9}$/;
// 身份证号
export const idcardValidation = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
// 邮编
export const zipcodeValidation = /^[a-zA-Z0-9 ]{6}$/;
// 密码
export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[1-9])(?=.*[\W]).{8,20}$/;
const formValidation = {
  // 姓名--中文
  nameRule: (rule, value, callback) => {
    if (value) {
      if (!chineseValidation.test(value)) {
        callback(rule.message);
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  // 手机号
  mobileRule: (rule, value, callback) => {
    if (value) {
      if (!mobileValidation.test(value)) {
        callback(rule.message);
      } else {
        callback();
      }
    } else {
      callback();
    }
  },
  // 身份证
  idcardRule: (rule, value, callback) => {
    if (!idcardValidation.test(value)) {
      callback(rule.message);
    } else {
      callback();
    }
  },
  // 身份证
  zipcodeRule: (rule, value, callback) => {
    if (!zipcodeValidation.test(value)) {
      callback(rule.message);
    } else {
      callback();
    }
  },
  // 密码
  passwordRule: (rule, value, callback) => {
    if (!passwordValidation.test(value)) {
      callback(rule.message);
    } else {
      callback();
    }
  }
};

export default formValidation;
