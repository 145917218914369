<template>
  <div class="customerInformationInputDetailBox">
    <headerTitle title="客户信息录入" :titles="titles" :showBtn="true"></headerTitle>
    <div class="customerInformationInputDetail">
      <header>{{ titles[0] }}</header>
      <customerInformationDetail :customId="id" :activeName="activeName" :type="type"></customerInformationDetail>
    </div>
  </div>
</template>
<script>
import headerTitle from '../../../components/title.vue';
import customerInformationDetail from '../../../components/customerInformation/customerInformationDetail.vue';
export default {
  name: 'CustomerInformationInputDetail',
  components: {
    headerTitle,
    customerInformationDetail
  },
  data() {
    return {
      type: '',
      titles: [],
      intentionStage: ['高中', '本科', '研究生'],
      activeName: 'customerInformationDetail',
      id: ''
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    switch (this.type) {
      case 'add':
        this.titles = ['新增客户'];
        break;
      case 'edit':
        this.titles = ['编辑客户信息'];
        break;
      case 'detail':
        this.titles = ['客户详情'];
        break;
    }
    //
  },
  methods: {}
};
</script>

<style lang="stylus" scoped>
.customerInformationInputDetailBox {
  box-sizing: border-box;
  font-size: 14px;

  .customerInformationInputDetail {
    background: #fff;
    padding: 0 20px;
  }

  header {
    border-bottom: solid 1px #D9DBDE;
    padding: 15px 0;
  }
}
</style>