module.exports = {
  /**
   * @description 网站标题
   */
  title: '超哥留学',
  /**
   * @description token key   token缓存的键名
   */
  TokenKey: 'JYKJAdmin',
  /**
   * @description tokenCookieExpires  token缓存时长
   */
  tokenCookieExpires: 7,
  /**
   * @description 请求超时时间，毫秒（默认30秒）
   */
  timeout: 30000,
  /**
   * @description 正式环境时页面输入等于forbiddenStr可以打开控制台
   */
  forbiddenStr: 'jinyitongqianduanniubi',
  /**
   * @description forbiddenStr可输入的字符
   */
  endlessLoopFlagStr: 'jinytogqadub'
};
