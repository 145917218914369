// import getParentOrParentName from './getParentOrParentName';
export default {
  inserted(el, binding) {
    const {
      value
    } = binding;
    // 自定义权限标识为NoLimit时不判断权限直接通过
    if (value.limit === 'NoLimit') {
      return;
    }
    // 尽量不要使用自动生成的权限标识(可能会有一些没想到的问题)
    // const limit = value.limit || `${value.route.name}:${getParentOrParentName(value)}:${value.type}`; // 权限标识  value.limit (自定义权限标识) || 自动生成权限标识(路由name + 父组件name + 按钮type)
    const limit = value.limit; // 权限标识  value.limit (自定义权限标识)
    const route = value.route;
    if (!limit) {
      throw Error('缺少权限标识!!!');
    }
    if (route.meta && route.meta.limits && route.meta.limits.indexOf(limit) > -1) {

    } else {
      console.log(123123);
      el.parentNode.removeChild(el);
    }
  }
};
