/**
 * * 本JS专门用于提供浏览器一侧本地化日志的实现
 */
// 引用时间格式化
function formatDate(date, format) {
  const time = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    "S+": date.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in time) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? time[k] : ("00" + time[k]).substr(("" + time[k]).length)
      );
    }
  }
  return format;
}
// indexDB的实例
var db;
// var objectStore
const request = indexedDB.open('forCSWorkbench');
request.onerror = function (event) {
  console.error('open indexedDB error');
};
request.onsuccess = function (event) {
  db = event.target.result;
  // console.log('now db is ' + db);
  // console.log('open indexedDB success');
};
/* 当发现目标表logs不存在的时候创建一个目标对象（表） */

request.onupgradeneeded = function (event) {
  db = event.target.result;
  // console.log('upgrade indexedDB success');
  if (!db.objectStoreNames.contains('mylogs')) {
    /*
     * 创建一个叫做 logs 的表，只能存储javascript类型的值，主键为id
     *
     * const objectStore = db.createObjectStore('mylogs', { keyPath :
     * 'time' })
     */
    /* 不用time作为主键是避免数据重复，毕竟到毫秒也有可能冲突 */

    // console.log('建立一个mylogs表');
    /* 创建一个叫做 logs 的表，可以存储任意类型的值，自动生成主键 */

    const objectStore = db.createObjectStore('mylogs', {
      autoIncrement: true
    });

    // 创建一个索引来通过时间time搜索，时间可能是重复的，所以不能使用 unique 索引。
    objectStore.createIndex('time_idx', 'time', {
      unique: false
    });

    // 使用事务的 oncomplete 事件确保在插入数据前对象仓库已经创建完毕
    objectStore.transaction.oncomplete = function (event) {
      // 将数据保存到新创建的对象仓库
      const transaction = db.transaction('mylogs', 'readwrite');
      const store = transaction.objectStore('mylogs');
      store.add({
        time: formatDate(new Date(), 'yyyy-MM-dd'),
        msg: '新建表的首次初始化数据'
      });

      store.add({
        deTime: formatDate(new Date(), 'yyyy-MM-dd'),
        msg: '记录日志的时间'
      });
      // console.log('向mylogs表中增加一条数据');
    };
  } else {
    // console.log('mylogs表之前已经建立了');
  }
};

// 删除数据
// function remove() {
//   var request = db.transaction('mylogs')
//     .objectStore('mylogs')
//     .delete(1);

//   request.onsuccess = function (event) {
//     // console.log('数据删除成功');
//   };
// }

function uaMatch() {
  // 正则表达式方式来判定user-agent字符串，得到当前访问浏览器（http代理）的版本
  var userAgent = navigator.userAgent;
  var rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
  var rFirefox = /(firefox)\/([\w.]+)/;
  var rOpera = /(opera).+version\/([\w.]+)/;
  var rChrome = /(chrome)\/([\w.]+)/;
  var rSafari = /version\/([\w.]+).*(safari)/;
  var ua = userAgent.toLowerCase();
  var match = rMsie.exec(ua);

  if (match != null) {
    return {
      browser: 'IE',
      version: match[2] || '0'
    };
  }
  match = rFirefox.exec(ua);
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    };
  }
  match = rOpera.exec(ua);
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    };
  }
  match = rChrome.exec(ua);
  if (match != null) {
    return {
      browser: match[1] || '',
      version: match[2] || '0'
    };
  }
  match = rSafari.exec(ua);
  if (match != null) {
    return {
      browser: match[2] || '',
      version: match[1] || '0'
    };
  }
  if (match == null) {
    return {
      browser: '',
      version: '0'
    };
  }
}

// 新增数据，最好不要用传参方式带入db，有可能会带入一个空的db    /*
// 写入的日志 的数据结构 采取json var data = { time : new Date().getTime(), msg :
//   '加入一条新的日志数据' }
//
// 调用addLog函数，插入一条数据data
// addLog(data, 1)
function addLog(logStr, cnt = 1) {
  const data = {
    time: formatDate(new Date(), 'yyyy-MM-dd'),
    msg: logStr
  };
  if (cnt <= 2) {
    if (db !== undefined) {
      // console.log('start to add log :db is ' + db);
      const transaction = db.transaction('mylogs', 'readwrite');
      // console.log('get transaction is ' + transaction);
      const store = transaction.objectStore('mylogs');
      // console.log('get objectStore is ' + transaction);

      const request = store.add(data);
      request.onsuccess = function (e) {
        // console.log('write log success，the data is' + data);
      };
      request.onerror = function (e) {
        console.error('write log fail', e);
      };
    } else {
      // 由于indexedDB是异步的，第一次打开页面时db可能还没有完成初始化，因此需要做一次1秒的延迟
      console.error('由于db还没有初始化完成，延迟1秒以后再记录日志');
      setTimeout(() => {
        addLog(data, cnt + 1);
      }, 1000);
    }
  } else {
    // 如果db还没有完成初始化，放弃记录日志
    console.error('由于db始终没有初始化完成，无法记录日志');
  }
}

export default {
  // 取日志，调这个就能获取日志
  getLog(fun, cnt = 1) {
    return new Promise((resolve, reject) => {
      if (cnt <= 2) {
        if (db !== undefined) {
          const transaction = db.transaction('mylogs', 'readwrite');
          const store = transaction.objectStore('mylogs');
          // const request = store.get(key)
          const request = store.getAll();
          request.onsuccess = function (e) {
            // console.log('get log success');
            // console.log(e.target.result);
            resolve({
              code: 2000,
              data: e.target.result
            });
          };
          request.onerror = function (e) {
            console.error('get log fail');
            reject(new Error({
              code: -1,
              msg: 'get log fail'
            }));
          };
        } else {
          console.error('由于db还没有初始化完成，延迟1秒以后再读取日志');
          setTimeout(() => {
            this.getLog(fun, cnt + 1);
          }, 3000);
        }
      } else {
        // 如果db还没有完成初始化，放弃记录日志
        console.error('由于db始终没有初始化完成，无法读取日志');
        reject(new Error({
          code: -1,
          msg: '由于db始终没有初始化完成，无法读取日志'
        }));
      }
    });
  },

  // 建议在页面离开的时候执行，避免资源占用和多个页面建立多个连接产生潜在的冲突。
  closeDb() {
    db.close();
    console.info('indexedDB关闭了');
  },

  /*
   * 生成和取得指定日志文件 yyyy-MM-dd-hh24 日志的下载 通过索引找到搜索的值然后结合游标循环，找到所有的日志，
     由于这个过程是异步的， 所以通过定时5秒后在写文件
   */
  downloadLog(agentlogId) {
    agentlogId = formatDate(agentlogId, 'yyyy-MM-dd');
    const transaction = db.transaction('mylogs');
    const store = transaction.objectStore('mylogs');
    const index = store.index('time_idx');
    const request = index.openCursor(IDBKeyRange.only(agentlogId));

    const browserMatch = uaMatch();
    const broserVer = browserMatch.browser + browserMatch.version;
    if (broserVer.indexOf('IE11') >= 0) {
      // alert('版本浏览器是IE11');
      const downloadFileName = agentlogId + '-' + sessionStorage.getItem('hospitalCode') + '-' + '-cc-agent.log';
      try {
        let file = 'data:text/plain;charset=utf-8,';
        request.onsuccess = function (e) {
          const cursor = e.target.result;
          if (cursor) {
            const result = cursor.value;
            file += JSON.stringify(result.msg.msg) + '\r\n';
            cursor.continue();
          }
        };
        setTimeout(() => {
          const blobObject = new Blob([file]);
          window.navigator.msSaveBlob(blobObject, downloadFileName);
        }, 5000);
      } catch (e) {
        alert('下载文件时出现错误' + e);
      }
    } else {
      // alert('其他浏览器');
      try {
        const downloadFileName = agentlogId + '-cc-agent.log';
        let file = 'data:text/plain;charset=utf-8,';
        request.onsuccess = function (e) {
          const cursor = e.target.result;
          if (cursor) {
            const result = cursor.value;

            // console.log(result);
            file += JSON.stringify(result.msg) + '\r\n';
            cursor.continue();
          }
        };
        setTimeout(() => {
          const downloadevent = document.createElement('a');
          downloadevent.href = file;
          downloadevent.target = '_blank';
          downloadevent.download = downloadFileName;
          document.body.appendChild(downloadevent);
          downloadevent.click();
          downloadevent.remove();
        }, 5000);
      } catch (e) {
        // alert('您的浏览器不支持记录本地日志。IE11浏览器则可能是因为在本地打开的网页' + e)
        // console.log('您的浏览器不支持记录本地日志。IE11浏览器则可能是因为在本地打开的网页' + e);
      }
    }
    // alert('在chrome浏览器中无需开发查看函数，通过开发者模式在application的indexDB中就可以看到')
  },

  /*
   * 定期清除日志，释放空间可以用到
   */
  deleteLog(agentlogId) {
    // alert('在chrome浏览器中无需开发清除函数，通过开发者模式在application的indexDB中就可以手动清除')
    if (db !== undefined) {
      const transaction = db.transaction('mylogs', 'readwrite');
      const store = transaction.objectStore('mylogs');
      // const request = store.get(key)
      // const request = store.delete(agentlogId)
      const index = store.index('time_idx');
      const request = index.openCursor(IDBKeyRange.only(agentlogId));
      request.onsuccess = function () {
        const cursor = request.result;
        if (cursor) {
          const deleteRequest = store.delete(cursor.primaryKey);
          deleteRequest.onsuccess = function (e) {
            // console.log('delete log success');
            // // console.log(e)
          };
          deleteRequest.onerror = function (e) {
            console.error('delete log fail');
          };
        }
      };
      request.onerror = function (e) {
        console.error('delete log fail');
      };
    } else {
      console.error('由于db还没有初始化完成，延迟3秒以后再删除日志');
      setTimeout(() => {
        this.deleteLog(agentlogId);
      }, 3000);
    }
  },

  /*
   * 写日志的函数
   */
  writeLog(loginfo) {
    const now = new Date();
    if (typeof (loginfo) === 'undefined') {
      loginfo = '';
    }
    const data = '【' + formatDate(now, 'yyyy-MM-dd hh:mm:ss') + '】' + loginfo;
    addLog(data, 1);
  }
};
