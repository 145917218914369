<template>
  <div class="searchBigBox">
    <div class="searchBox">
      <!-- 用户名称 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">用户名称：</div>
        <div class="searchInputBox">
          <el-input clearable v-model="searchObj.name" placeholder="请输入用户名称" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div>
      <!-- 用户账号 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">用户账号：</div>
        <div class="searchInputBox">
          <el-input clearable v-model="searchObj.username" placeholder="请输入用户账号" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div>
      <!-- 角色 -->
      <!-- <div class="searchInputBigBox">
        <div class="searchInputLabel">角色：</div>
        <div class="searchInputBox">
          <el-select clearable v-model="searchObj.roleId" placeholder="请选择角色" @change="searchClick">
            <el-option v-for="(item, index) in roleList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div> -->
      <!-- 有效时间 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">有效时间：</div>
        <div class="searchInputBox">
          <el-date-picker clearable v-model="searchObj.time" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="searchClick"></el-date-picker>
        </div>
      </div>
      <!-- 是否有效 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">是否有效：</div>
        <div class="searchInputBox">
          <el-select clearable v-model="searchObj.status" placeholder="请选择是否有效" @change="searchClick">
            <el-option v-for="(item, index) in validList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
        <div class="buttonBox">
          <div class="button">
            <el-button type="primary" size="small" @click="searchClick" round plain>查询</el-button>
          </div>
          <div class="button">
            <el-button type="primary" size="small" @click="addClick" round plain>新建</el-button>
          </div>
          <div class="button">
            <el-button type="primary" size="small" @click="importClick" round plain>导入CRM用户</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data() {
    return {};
  },
  props: {
    searchObj: Object,
    validList: Array,
    roleList: Array
  },
  methods: {
    searchClick() {
      this.$emit('searchClick');
    },
    addClick() {
      this.$emit('addClick');
    },
    importClick() {
      this.$emit('importClick');
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBigBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 40px;
      margin-bottom: 20px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: 50px;

    .button .el-button {
      width: 80px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-right: 11px;
    }
  }
}
</style>