<template>
  <div>
    <div id="allocationChart" :style="{ height: height, width: width }"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'CircularDiagram',
  data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        // this.setOptions(val);
        this.$nextTick(() => {
          this.initChart();
        });
      }
    }
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    chartData: {
      type: Array,
      required: true
    },
    threeTotal: {
      type: [String, Number],
      required: true,
      default: 0
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initChart();
    // });
  },
  methods: {
    loadings() {
      this.chart.showLoading({
        text: '统计中，请稍候...',
        maskColor: '#fff',
        textColor: '#000',
        color: 'rgba(89,137,247)'
      });
    },
    initChart() {
      const allocationChart = document.getElementById('allocationChart');
      this.chart = echarts.init(allocationChart);
      this.loadings();
      this.setOptions(this.chartData);
    },
    setOptions(datas) {
      this.chart.hideLoading();
      this.chart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          icon: 'circle'
        },
        title: {
          text: this.threeTotal,
          left: 'center',
          top: '60%',
          textStyle: {
            color: '#333333',
            fontSize: 14,
            align: 'center'
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '52%',
          style: {
            text: '客户分配情况',
            textAlign: 'center',
            fill: '#333',
            fontSize: 14
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            color: ['#DF80FC', '#FEC954', '#586BFB'],
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: datas
          }
        ]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
<style lang="stylus" scoped></style>