<template>
  <div class="customerFollowDetailBox" v-loading="loading">
    <headerTitle title="客户跟进" :titles="titles" :showBtn="true"></headerTitle>
    <div class="customerFollowDetail">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="新建跟进记录" name="createRecord">
          <createRecord :activeName="activeName" @activeNameChange="activeNameChange" :id="id" :customId="customId"></createRecord>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="followUpRecords">
          <followUpRecords :type="type" :activeName="activeName"></followUpRecords>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import headerTitle from '../../../components/title.vue';
import followUpRecords from '../../../components/customerInformation/followUpRecords.vue';
import createRecord from './component/createRecord.vue';
export default {
  name: 'CustomerFollowDetail',
  components: { headerTitle, createRecord, followUpRecords },
  data() {
    return {
      loading: false,
      titles: ['查看详情'],
      activeName: 'createRecord',
      searchObj: {
        time: []
      },
      type: 'sellers',
      id: '', // 任务id
      customId: '' // 客户id
    };
  },
  props: {},
  watch: {},
  computed: {},
  mounted() {
    this.activeName = sessionStorage.getItem('activeName') || 'createRecord';
    this.customId = this.$route.query.customId;
    this.id = this.$route.query.id;
  },
  beforeDestroy() {
    sessionStorage.removeItem('activeName');
  },
  methods: {
    activeNameChange(item) {
      sessionStorage.setItem('activeName', item);
      this.activeName = item;
    },
    handleClick() {
      sessionStorage.setItem('activeName', this.activeName);
    }
  }
};
</script>
<style lang="stylus" scoped>
.customerFollowDetail {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}
</style>