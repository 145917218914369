<template>
  <div class="createRecord" v-loading="loading">
    <div class="contentBox">
      <!-- 客户信息 -->
      <div class="itemBox">
        <div class="title">客户信息</div>
        <div class="lineBox">
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel flexs-alignCenter">
                <span>*</span>
                潜在客户：
              </div>
              <el-input size="medium" clearable v-model="dataObj.name" placeholder="请输入潜在客户名称"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">意向阶段：</div>
              <el-select size="medium" clearable v-model="dataObj.intentionStage" placeholder="请选择意向阶段">
                <el-option v-for="(item, index) in intentionStage" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">意向国家/地区 ：</div>
              <el-input size="medium" clearable v-model="dataObj.intentionCountry" placeholder="请输入意向国家/地区"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">计划入学季：</div>
              <el-input size="medium" clearable v-model="dataObj.planStudentDate" placeholder="请输入计划入学季"></el-input>
            </div>
          </div>
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读院校：</div>
              <el-input size="medium" clearable v-model="dataObj.school" placeholder="请输入在读院校"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读年级：</div>
              <el-input size="medium" clearable v-model="dataObj.grade" placeholder="请输入在读年级"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读专业：</div>
              <el-input size="medium" clearable v-model="dataObj.speciality" placeholder="请输入在读专业"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读成绩：</div>
              <el-input size="medium" clearable v-model="dataObj.score" placeholder="请输入在读成绩"></el-input>
            </div>
          </div>
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">标化成绩：</div>
              <el-input size="medium" clearable v-model="dataObj.scoreStandard" placeholder="请输入标化成绩"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                手机号：
              </div>
              <el-input size="medium" clearable v-model="dataObj.phone" placeholder="请输入手机号"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                微信号：
              </div>
              <el-input size="medium" clearable v-model="dataObj.wxNumber" placeholder="请输入微信号"></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">电子邮件：</div>
              <el-input size="medium" clearable v-model="dataObj.email" placeholder="请输入电子邮件"></el-input>
            </div>
          </div>
        </div>
      </div>
      <!-- 资源信息 -->
      <div class="itemBox">
        <div class="title">资源信息</div>
        <div class="lineBox">
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">来源渠道：{{ dataObj.sourceChannel || '—— ——' }}</div>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">渠道明细：{{ dataObj.channelDetail || '—— ——' }}</div>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">数据提供方：{{ dataObj.dataProvider || '—— ——' }}</div>
            </div>
            <!-- 占位 -->
            <div class="inputBox flexs-alignCenter"></div>
          </div>
        </div>
      </div>
      <!-- 跟进信息 -->
      <div class="itemBox">
        <div class="title">跟进信息</div>
        <div class="lineBox">
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                资源状态：
              </div>
              <el-select size="medium" clearable v-model="dataObj.customType" placeholder="请选择资源状态">
                <el-option v-for="(item, index) in resourceStatus" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">联系类型：</div>
              <el-select size="medium" clearable v-model="dataObj.contactType" placeholder="请选择联系类型">
                <el-option v-for="(item, index) in contactType" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter marginRight">
              <div class="inputLabel">提醒下次回访时间：</div>
              <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" clearable v-model="dataObj.revisitTime" type="datetime" placeholder="选择提醒下次回访时间"></el-date-picker>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">竞签机构：</div>
              <el-input size="medium" clearable v-model="dataObj.institution" placeholder="请输入竞签机构"></el-input>
            </div>
          </div>
          <!-- 一行 -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">预计签约金额：</div>
              <el-input-number controls-position="right" size="medium" :min="0" :precision="2" clearable v-model="dataObj.planBidAmount" placeholder="请输入预计签约金额"></el-input-number>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">预计签约时间：</div>
              <el-date-picker size="medium" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable v-model="dataObj.planTime" type="date" placeholder="选择预计签约时间"></el-date-picker>
            </div>
            <!-- 占位 -->
            <div class="inputBox flexs-alignCenter"></div>
            <!-- 占位 -->
            <div class="inputBox flexs-alignCenter"></div>
          </div>
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">联系内容：</div>
              <el-input size="medium" type="textarea" :rows="4" placeholder="请输入联系内容" v-model="dataObj.content"></el-input>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="itemBox">
        <div class="title">通话记录上传</div>
        <!-- -->
        <div class="flexs">
          上传文件：
          <!-- :="toChannelRequest" beforeUpload :auto-upload="false"-->
          <el-upload class="upload-demo" action="" :http-request="toChannelRequest" drag multiple :file-list="fileList" :on-preview="handlePreview" :on-remove="handleRemove" accept=".docx,.doc">
            <div class="flexs-alignCenter">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/folder.png" alt="" />
              <div class="el-upload__text">支持word格式</div>
            </div>
            <div class="text">上传文件</div>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- 文件预览 -->
    <el-dialog :visible.sync="dialogFileVisible" title="文件预览" :show-close="true" :close-on-click-modal="false">
      <iframe v-if="docxUrl" :src="`https://view.officeapps.live.com/op/view.aspx?src=${docxUrl}`" frameborder="0" width="100%" height="500px"></iframe>
      <!-- <vue-office-docx @error="errorHandler" v-if="docxUrl" :src="docxUrl" style="height: 100vh" /> -->
    </el-dialog>
    <footer><el-button type="primary" round @click="save">保存</el-button></footer>
  </div>
</template>
<script>
// 引入VueOfficeDocx组件
// import VueOfficeDocx from '@vue-office/docx';
// 引入相关样式
// import '@vue-office/docx/lib/index.css';
import { uploadFile } from 'api/index.js';
import { getCustomerDetail, addCustomFollowUpRecord } from 'api/customerManagement.js';
import dictionary from '@/utils/dictionary.js';
export default {
  name: 'CreateRecord',
  components: {
    // VueOfficeDocx
  },
  data() {
    return {
      loading: false,
      contactType: dictionary.contactType,
      resourceStatus: dictionary.resourceStatus,
      intentionStage: dictionary.intentionStage,
      dataObj: {},
      checkObj: {
        name: '潜在客户名称',
        phone: '客户手机号',
        wxNumber: '客户微信号',
        customType: '资源状态'
      },
      fileList: [],
      uploadApi: process.env.VUE_APP_JYKJ_HTTPS + 'pc/sysBanner/uploadFile',
      uid: '',
      dialogFileVisible: false,
      docxUrl: ''
      // pdfUrl: ''
    };
  },
  props: {
    activeName: String
  },
  watch: {
    activeName: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      immediate: true, // 进入页面后立即自动调用handler函数
      handler(newValue) {
        if (newValue == 'createRecord') {
          this.getDetail();
        }
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    downLoad() {
      this.dialogFileVisible = true;
    },
    //
    save() {
      let flag = true;
      for (const i in this.checkObj) {
        if (!this.dataObj[i]) {
          flag = false;
          this.$message({
            type: 'error',
            message: `${this.checkObj[i]}不可为空！`
          });
          return;
        }
      }
      if (!flag) {
        return false;
      }
      // if (!this.$utils.nameRuleCheck(this.dataObj.name)) {
      //   this.$message({
      //     type: 'error',
      //     message: '潜在客户名称输入格式有误！'
      //   });
      //   return false;
      // }
      if (!this.$utils.phoneRuleCheck(this.dataObj.phone)) {
        this.$message({
          type: 'error',
          message: '客户手机号输入格式有误！'
        });
        return false;
      }
      // if (!this.$utils.wxNumberRuleCheck(this.dataObj.wxNumber)) {
      //   this.$message({
      //     type: 'error',
      //     message: '客户微信号输入格式有误！'
      //   });
      //   return false;
      // }
      if (this.dataObj.email && !this.$utils.emailRuleCheck(this.dataObj.email)) {
        this.$message({
          type: 'error',
          message: '客户邮箱输入格式有误！'
        });
        return false;
      }
      const urlList = [];
      if (this.fileList.length) {
        this.fileList.forEach(item => {
          urlList.push(item.url);
        });
      }
      const data = {
        ...this.dataObj,
        taskId: this.$route.query.id,
        urlList: urlList
      };
      data.customId = data.id;
      this.loading = true;
      addCustomFollowUpRecord(data).then(res => {
        this.loading = false;
        console.log(res.data);
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: '新建成功！'
          });
          this.$emit('activeNameChange', 'followUpRecords');
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    getDetail() {
      this.loading = true;
      getCustomerDetail({ id: this.$route.query.customId }).then(res => {
        this.loading = false;
        if (res.code == '2000') {
          this.dataObj = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 导入
    toChannelRequest(files) {
      const file = files.file;
      this.uid = file.uid;
      this.formData = new FormData();
      this.formData.append('file', file);
      uploadFile(this.formData).then(res => {
        if (res.code == 2000) {
          const obj = {
            uid: this.uid,
            url: res.data,
            name: res.data
          };
          this.fileList.push(obj);
          this.uid = '';
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    handlePreview(file) {
      console.log(file, 'file');
      const FileUrl = file.url;
      this.docxUrl = '';
      this.pdfUrl = '';
      const flag = FileUrl.includes('.docx');
      const flag1 = FileUrl.includes('.doc');
      const flag2 = FileUrl.includes('.txt');
      if (flag) {
        this.docxUrl = FileUrl;
      } else if (flag1) {
        this.docxUrl = FileUrl;
      } else if (flag2) {
        this.pdfUrl = FileUrl;
      }
      console.log(this.docxUrl, 'this.docxUrl');
      this.dialogFileVisible = true;
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (file.uid == this.fileList[i].uid) {
          this.fileList.splice(i, 1);
        }
      }
    },
    errorHandler(val) {
      console.log(val);
    }
  }
};
</script>

<style lang="stylus" scoped>
.createRecord {
  .contentBox {
    .itemBox {
      border-bottom: 1px solid #EEEEEE;
      padding: 20px 0;

      .title {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        margin-bottom: 15px;
      }

      .inputBigBox {
        margin-bottom: 15px;

        .inputBox {
          flex: 1;

          .inputLabel {
            flex-shrink: 0;

            span {
              color: #FF0000;
            }
          }

          >>> .el-input, >>>.el-input-number--medium {
            width: auto;
          }

          >>> .el-input__inner {
            width: 150px;
          }

          >>>.el-textarea {
            width: 400px;
          }
        }
      }

      .inputBigBox:last-of-type {
        margin-bottom: 0;
      }
    }

    .itemBox:last-child {
      border: none;
    }

    .marginRight {
      margin-right: 40px;
    }
  }

  footer {
    padding-top: 20px;

    >>>.el-button {
      width: 250px;
    }
  }

  >>>.el-upload-dragger {
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    width: 367px;
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    color: #666666;

    .el-upload__text {
      margin-left: 11px;
    }

    .text {
      font-size: 14px;
      color: #2797FF;
    }
  }
}
</style>