<template>
  <div class="contents">
    <headerTitle title="账号管理"></headerTitle>
    <div class="accountManagementBigBox">
      <search :searchObj="searchObj" :validList="validList" :roleList="roleList" @searchClick="pageclick(1)" @addClick="addClick" @importClick="importClick"></search>
      <list :dataList="dataList" @updateClick="updateClick" @deleteAccountClick="deleteAccountClick" v-loading="loading"></list>
      <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick"></paging>
      <!-- 新建 -->
      <el-dialog :title="dialogType == 'add' ? '新增' : '编辑'" :visible.sync="dialogVisible" width="35%" :show-close="false" :close-on-click-modal="false" center>
        <div>
          <!--  -->
          <div class="inputBigBox">
            <div class="inputLabel">用户名称：</div>
            <div class="inputBox">
              <el-input v-model="dataobj.name" placeholder="请输入用户名称"></el-input>
            </div>
          </div>
          <!-- 账号 -->
          <div class="inputBigBox">
            <div class="inputLabel">账号：</div>
            <div class="inputBox">
              <el-input v-model="dataobj.username" placeholder="请输入账号"></el-input>
            </div>
          </div>
          <!-- 密码 -->
          <div class="inputBigBox">
            <div class="inputLabel">密码：</div>
            <div class="inputBox">
              <el-input type="password" v-model="dataobj.password" placeholder="请输入密码" show-password></el-input>
            </div>
          </div>
          <!-- 角色 -->
          <div class="inputBigBox">
            <div class="inputLabel">角色：</div>
            <div class="inputBox">
              <el-select v-model="dataobj.roleId" placeholder="请选择角色" @change="roleChange" :clearable="true">
                <el-option v-for="(item, index) in addRoleList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <!-- 用户类型 -->
          <div class="inputBigBox">
            <div class="inputLabel">用户类型：</div>
            <div class="inputBox">
              <el-select v-model="dataobj.userType" placeholder="请选择用户类型" :clearable="true">
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </div>
          </div>
          <!-- 有效时间 -->
          <div class="inputBigBox">
            <div class="inputLabel">有效时间至：</div>
            <div class="inputBox">
              <el-date-picker
                v-model="dataobj.expirationDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择有效时间"
                :picker-options="{
                  disabledDate: disabledDateFun
                }"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addClose">取 消</el-button>
          <el-button type="primary" :disabled="submitLoading" @click="addSubmit">
            {{ submitLoading ? '请稍候...' : '确 定' }}
          </el-button>
        </span>
      </el-dialog>
      <!-- 批量导入弹窗 -->
      <el-dialog title="上传目录" :visible.sync="importVisible" width="500px" :close-on-click-modal="false" center>
        <div>
          <el-upload class="upload-demo" action="" drag :multiple="false" :show-file-list="false" :http-request="toChannelRequest" accept=".xls, .xlsx">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" v-if="!toImportName">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__text" v-else>{{ toImportName }}</div>
            <div class="el-upload__tip" slot="tip">
              <div style="margin-top: 8px; color: #ff4141; display: flex; justify-content: space-between; align-items: center">
                仅支持Excel文件
                <a style="color: #2797ff" href="https://file.chaogexitongban.net/others/app/c5f2e998a3c0bb29cf2bfea2d0f940e0.xlsx" download="客户信息录入导入模板">下载模板</a>
              </div>
            </div>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button round @click="toImportClose" style="width: 48%; border-radius: 25px">取 消</el-button>
          <el-button round style="width: 48%; border-radius: 25px" type="primary" :disabled="toImportLoading" @click="sureImport">
            {{ toImportLoading ? '请稍候...' : '确 定' }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import paging from '../../components/paging.vue';
import search from './component/search.vue';
import list from './component/list.vue';
import headerTitle from '../../components/title.vue';
import { getAccountManagementList, getRoleList, submitAddAccount, submitUpdateAccount, getAccountData, uploadExcel } from 'api/systemManagement.js';
export default {
  name: 'AccountManagement',
  data() {
    return {
      searchObj: {},
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      dataList: [],
      loading: false,
      validList: [
        {
          name: '全部',
          code: null
        },
        {
          name: '有效',
          code: 0
        },
        {
          name: '无效',
          code: 1
        }
      ],
      typeList: [
        {
          name: '后台用户',
          code: 0
        },
        {
          name: '小程序用户',
          code: 1
        },
        {
          name: '通用',
          code: 2
        }
      ],
      roleList: [],
      addRoleList: [],
      dialogVisible: false,
      dialogType: '',
      submitLoading: false,
      dataobj: {},
      importVisible: false,
      toImportLoading: false,
      formData: '',
      toImportName: ''
    };
  },
  methods: {
    importClick() {
      this.importVisible = true;
    },
    // 关闭导入弹窗
    toImportClose() {
      this.toImportName = '';
      this.formData = '';
      this.importVisible = false;
    },
    // 导入
    toChannelRequest(files) {
      const file = files.file;
      this.toImportName = file.name;
      this.formData = new FormData();
      this.formData.set('file', file);
    },
    sureImport() {
      if (!this.formData) {
        this.$message({
          type: 'warning',
          message: '请选择导入文件'
        });
        return;
      }
      this.$set(this, 'toImportLoading', true);
      uploadExcel(this.formData)
        .then(res => {
          this.$set(this, 'toImportLoading', false);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            });
            this.pageclick(1);
            this.importVisible = false;
            this.toImportClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.$set(this, 'toImportLoading', false);
        });
    },
    disabledDateFun(val) {
      const time = new Date().getTime();
      return val.getTime() < time - 1000 * 60 * 60 * 24;
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      const searchObj = JSON.parse(JSON.stringify(this.searchObj));
      searchObj.time = null;
      searchObj.startDate = this.searchObj.time && this.searchObj.time.length ? this.searchObj.time[0] : '';
      searchObj.endDate = this.searchObj.time && this.searchObj.time.length ? this.searchObj.time[1] : '';
      delete searchObj.time;
      this.loading = true;
      getAccountManagementList({
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        ...searchObj
      })
        .then(res => {
          this.loading = false;
          if (res.code == 2000) {
            //
            this.dataList = res.data && res.data.list ? res.data.list : [];
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取角色列表
    getRoleListData() {
      getRoleList({
        ifPage: false
      }).then(res => {
        if (res.code == 2000) {
          let roleList = [];
          if (res.data && res.data.list) {
            roleList = res.data.list;
          }
          this.addRoleList = JSON.parse(JSON.stringify(roleList));
          this.roleList = JSON.parse(JSON.stringify(roleList));
          this.dataobj.roleName = this.addRoleList[0].name;
          this.dataobj.roleId = this.addRoleList[0].id;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    roleChange(id) {
      for (let i = 0; i < this.addRoleList.length; i++) {
        if (id == this.addRoleList[i].id) {
          this.dataobj.roleName = this.addRoleList[i].name;
          break;
        }
      }
      this.$forceUpdate();
    },
    addClick(type) {
      this.dialogType = type ? type : 'add';
      this.dialogVisible = true;
    },
    addClose() {
      this.dialogVisible = false;
      this.dataobj = {};
    },
    addSubmit() {
      const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_.,/;:!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^.,/;:&*`~()-+=]+$)(?![0-9\\W_!@#.,/;:$%^&*`~() -+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#.,/;:$%^&*`~()-+=]+$)[a-zA-Z0-9\\ W_!@#$.,/;:%^&*`~()-+=]{8,16}$/;
      if (!this.dataobj.name) {
        this.$message({
          type: 'warning',
          message: '请输入用户名称!!!'
        });
        return false;
      }
      if (!this.dataobj.username) {
        this.$message({
          type: 'warning',
          message: '请输入账号!!!'
        });
        return false;
      }
      if (this.dialogType == 'add') {
        if (!this.dataobj.password) {
          this.$message({
            type: 'warning',
            message: '请输入密码!!!'
          });
          return false;
        }
        if (!reg.test(this.dataobj.password)) {
          this.$message({
            type: 'warning',
            message: '密码需8-16位且包含小写字母、多个不同大写字母、数字及特殊字符!!!'
          });
          return false;
        }
      }
      if (!this.dataobj.roleId) {
        this.$message({
          type: 'warning',
          message: '请选择角色!!!'
        });
        return false;
      }
      if (this.dataobj.userType != 0 && this.dataobj.userType != 1 && this.dataobj.userType != 2) {
        this.$message({
          type: 'warning',
          message: '请选择用户类型!!!'
        });
        return false;
      }
      if (!this.dataobj.expirationDate) {
        this.$message({
          type: 'warning',
          message: '请选择有效时间!!!'
        });
        return false;
      }
      this.submitLoading = true;
      let fun = '';
      switch (this.dialogType) {
        case 'add':
          fun = submitAddAccount;
          break;
        case 'update':
          fun = submitUpdateAccount;
          this.dataobj.isDelete = 0;
          break;
      }
      fun(this.dataobj)
        .then(res => {
          this.submitLoading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.getList();
            this.addClose();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    updateClick(item) {
      this.loading = true;
      getAccountData({
        id: item.id
      })
        .then(res => {
          this.loading = false;
          if (res.code == 2000) {
            this.dataobj = res.data;
            this.addClick('update');
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteAccountClick(item) {
      this.loading = true;
      submitUpdateAccount({
        id: item.id,
        isDelete: 1,
        username: item.username
      })
        .then(res => {
          this.loading = false;
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.getRoleListData();
    this.getList();
  },
  components: {
    search,
    list,
    paging,
    headerTitle
  }
};
</script>
<style lang="stylus" scoped>
.accountManagementBigBox {
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;

  .inputBigBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .inputLabel {
      width: 100px;
    }

    .inputBox {
      flex: 1;
      display: flex;
      align-items: center;

      .dialogCity {
        margin-right: 20px;
      }
    }
  }
}

>>>.el-upload, >>>.el-upload-dragger {
  width: 100%;
}
</style>